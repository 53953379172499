





































































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { WidgetTextPropertiesModel } from '@/packages/template-renderer/types/Widget';
import CssInputNumber from '../CssInputNumber.vue';
import InputColorPicker from '../InputColorPicker.vue';

@Component({
    components: {
        CssInputNumber,
        InputColorPicker,
    },
})
export default class TypographyWidgetForm extends Vue {
    @Prop() model!: WidgetTextPropertiesModel;
    @Prop() fontSizes!: number[];

    onPropertyChange(propertyName: string, propertyValue: number | string) {
        this.$emit('change', { [propertyName]: propertyValue });
    }

    get hasBorder() {
        return 'border' in this.model;
    }
}
