






















































































import module from './store';
import { PadinationLoadOptions, PAGINATION_ACTIONS, PAGINATION_MUTATIONS } from '@/store/modules/paginator';
import { Column } from 'ant-design-vue/types/table/column';
import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { BackgroundPreview, MediaListFilters } from '@/modules/media/models';
import { debounce } from '@/shared/utils';
import { FamilyType, ListFilterParams } from '@/shared/models';
import {ChangeBackgroundParams} from '@/services/api/adapters/MediaApiAdapters';
import UploadModal from './UploadModal.vue';
import { FamiliesType } from '@shared/models/FamiliesType';

const ns = namespace('admin.backgrounds');
const pg = namespace('admin.backgrounds/pg');

@Component<Backgrounds>({
    created() {
        this.$registerVuexModule('admin.backgrounds', module);
    },
    components: {
        UploadModal,
    },
})
export default class Backgrounds extends Vue {
    @pg.State
    public params!: ListFilterParams;
    @pg.State
    public loading!: boolean;
    @pg.Getter
    public pageSize!: number;
    @pg.Getter
    public total!: number;
    @pg.Getter
    public pageIndex!: number;
    @pg.Getter
    public pageItems!: BackgroundPreview[];
    @ns.State
    public filterParams!: MediaListFilters;
    @ns.State
    public families!: FamiliesType[];

    @pg.Action(PAGINATION_ACTIONS.load)
    public loadPage!: (options?: PadinationLoadOptions) => Promise<boolean>;

    @pg.Mutation(PAGINATION_MUTATIONS.setParams)
    public setParams!: (params: Partial<ListFilterParams>) => void;
    @ns.Action('delete') delete!: (payload: number[]) => Promise<void>;
    @ns.Action('changeBackground') changeBackground!: (params: ChangeBackgroundParams) => Promise<void>;
    @ns.Action('getFamilies') getFamilies!: (payload: Partial<ListFilterParams>) => Promise<void>;
    @ns.Action('getFilterParams') getFilterParams!: () => Promise<void>;
    @pg.Mutation(PAGINATION_MUTATIONS.resetData) resetData!: () => void;

    showUploadModal = false;
    editableId = '';
    saveInProcess = false;
    changedName = '';

    get tags() {
        return this.families?.map(family => ({ label: family.name, value: family.name }));
    }

    mounted() {
        this.getFamilies({ options: FamilyType.BackgroundFamily });
        this.loadPage();
    }

    destroyed() {
        this.resetData();
    }

    onChange(e: any) {
        this.changedName = e.target.value;
    }

    onCancel() {
        this.editableId = '';
        this.changedName = '';
    }

    async onSave(background: BackgroundPreview) {
        if (this.changedName.trim()) {
            this.saveInProcess = true;
            try {
                await this.changeBackground({
                    backgroundId: background.id,
                    familyId: background?.family?.id,
                    customName: this.changedName,
                });
                this.onCancel();
                this.loadPage({ page: this.pageIndex });
            } catch (error) {
                console.error(error);
            } finally {
                this.saveInProcess = false;
            }
        }
    }

    onTableParamsChanged(paging: { current: number }) {
        this.loadPage({ page: paging.current - 1 });
    }

    async deleteBackgrond(id: number) {
        await this.delete([id]);
        this.loadPage({ page: this.pageIndex });
    }

    afterUpload(bg: BackgroundPreview) {
        if (bg.tag && !this.filterParams.tags.includes(bg.tag)) {
            this.getFilterParams();
        }
        this.loadPage({ page: this.pageIndex });
    }

    onSearchChange(value: string | Event) {
        this.setParams({ query: typeof value === 'string' ? value : (value.target as HTMLInputElement).value });
        this.debouncedLoad();
    }

    updateParams(tags: string[]) {
        const tagIds: number[] = [];
        tags.forEach(t => {
            const id = this.families.find(family => family.name === t)?.id || 0;
            tagIds.push(id);
        });
        this.setParams({ familyIds: tagIds });
        this.debouncedLoad();
    }

    debouncedLoad = debounce(() => {
        this.loadPage({ page: 0 });
    }, 500);

    columns: Partial<Column>[] = [
        { title: 'Family', dataIndex: 'family.name' },
        { title: 'Name', dataIndex: 'name', scopedSlots: { customRender: 'name' } },
        { title: 'Uploaded', dataIndex: 'savedDt', scopedSlots: { customRender: 'date' } },
        { title: 'Width', dataIndex: 'width', scopedSlots: { customRender: 'dimension' } },
        { title: 'Height', dataIndex: 'height', scopedSlots: { customRender: 'dimension' } },
        { title: 'Url', dataIndex: 'defaultUrl', scopedSlots: { customRender: 'url' } },
        { title: 'Preview', key: 'previewUrl', scopedSlots: { customRender: 'preview' } },
        { title: 'Action', dataIndex: 'id', scopedSlots: { customRender: 'action' } },
    ];
}
