









































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { WidgetBaseModel } from '@/packages/template-renderer/types/Widget';
import CssInputNumber from '../CssInputNumber.vue';

@Component({
    components: {
        CssInputNumber,
    },
})
export default class BaseWidgetForm extends Vue {
    @Prop() model!: WidgetBaseModel;

    handleUpdateModel(name: string, value: number | string | number[]) {
        this.$emit('change', { [name]: value });
    }
}
