


























import { Component, Prop, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { FileData } from '@adminServices/EditorApiAdapter';
import { LayerImage } from '@/shared/models';
import { AdminEditorState } from '../store';

const ns = namespace('admin.editor');

@Component
export default class ImageUploader extends Vue {
    @Prop() value!: string;
    uploadedLayer!: LayerImage;
    @ns.Action uploadLayer!: ({ file, name }: FileData) => Promise<LayerImage[]>;
    @ns.Action deleteLayer!: (imageUrl: string) => Promise<void>;

    isLoading = false;

    get selectedLayer() {
        return (this.$store.state['admin.editor'] as AdminEditorState).layers.find((layer) => layer.defaultUrl === this.value);
    }

    get buttonText() {
        return this.selectedLayer ? this.value : 'Upload';
    }

    async handleUploadLayer(options: any) {
        try {
            this.isLoading = true;
            const data = await this.uploadLayer({ file: options.file, name: options.file.name });
            this.$emit('change', data[0].defaultUrl);
        } catch (error) {
            throw error;
        } finally {
            this.isLoading = false;
        }
    }

    async handleDeleteLayer() {
        try {
            await this.deleteLayer(this.value);
            this.$emit('delete', this.selectedLayer?.imageId);
        } catch (error) {
            throw error;
        }
    }
}
