





























import { Component, Vue } from 'vue-property-decorator';
import {EDITOR_MUTATIONS, Icons, UpdateTemplateSchema, UpdateWidget} from '@modules/admin/Editor/store';
import { namespace } from 'vuex-class';
import { BaseWidgetForm } from '../commonForms';
import InputColorPicker from '../InputColorPicker.vue';
import PickLogo from '@modules/editor/components/PickLogo.vue';
import { TemplateWidgetsModels } from '@/packages/template-renderer/types/Widget';

const ns = namespace('admin.editor');

@Component({
    components: {
        BaseWidgetForm,
        InputColorPicker,
        PickLogo,
    },
})
export default class PropertiesListIcon extends Vue {
    @ns.Getter activeWidget!: TemplateWidgetsModels;
    @ns.State icons!: Icons;
    @ns.Action getIcons!: () => void;
    @ns.Mutation(EDITOR_MUTATIONS.updateWidgetProperties)
    updateWidgetProperties!: ({ key: value }: UpdateWidget) => void;
    @ns.Mutation(EDITOR_MUTATIONS.updateTemplateSchema)
    updateTemplateSchema!: ({ propertyName, propertyValue }: UpdateTemplateSchema) => void;

    schema = {
        type: 'icon',
        id: 1,
        label: '',
        isColorized: false,
        order: 1,
    };

    get model() {
        return { value: this.activeWidget.defaultValue };
    }

    handleUpdateModel(name: string, value: string) {
        this.updateWidgetProperties({ [name]: value });
    }
}
