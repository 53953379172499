import { TemplateSchema, TemplateSchemaModel } from '@/packages/template-renderer/types/Template';
import {
    isButtonModel,
    isTextModel,
    TemplateWidgets,
    TemplateWidgetsModels,
    TextProperties,
    WidgetBase,
    WidgetBaseModel,
    WidgetButtonModel,
    WidgetTextPropertiesModel,
    ButtonWidget,
    TextWidget,
} from '@/packages/template-renderer/types/Widget';
import { createHash } from '@/shared/utils';
import { valueOrAuto, getCssPosition, addCssUnit } from '@shared/utils/style';

function modelToBaseStyles(widget: WidgetBaseModel) {
    return {
        width: valueOrAuto(widget.width),
        height: valueOrAuto(widget.height),
        ...getCssPosition(widget),
        padding: `${widget.paddings[0]}px ${widget.paddings[1]}px`,
        margin: `${widget.margins[0]}px ${widget.margins[1]}px`,
        zIndex: widget.zIndex,
    } as Partial<{ [key in keyof WidgetBase]: string | number }>;
}

function modelToTextStyles<W extends WidgetTextPropertiesModel>(widget: W) {
    return {
        color: widget.color,
        backgroundColor: widget.backgroundColor,
        fontSize: `${widget.fontSize}px`,
        fontWeight: widget.fontWeight,
        letterSpacing: widget.letterSpacing ? `${widget.letterSpacing}em` : 'normal',
        lineHeight: `${widget.lineHeight}em`,
        whiteSpace: widget.whiteSpace || 'pre-wrap',
        // TODO align
    } as Partial<{ [key in keyof TextProperties]: string | number }>;
}

export function getValuesPairFromCss(value?: string) {
    const [, horizontal, vertical] = value?.match(/([\d\.]+).+\s([\d\.]+).+/) || [];
    return [parseNumber(horizontal) ?? 0, parseNumber(vertical) ?? 0] as [number, number];
}

export function getBorderModel(value?: string) {
    const [, width = 0, color = '#fff'] = value?.match(/([\d\.]+).+\s(.+)/) || [];
    return { width, color };
}

export function parseNumber(value?: string) {
    if (!value) return 0;
    try {
        const result = parseFloat(value);
        return isNaN(result) ? 0 : result;
    } catch (error) {
        console.warn('Failed to parse css property');
    }
}

export function templateSchemaToSchemaModel(template: TemplateSchema): TemplateSchemaModel {
    const result = {
        backgroundImage: template.backgroundImage,
        backgroundColor: template.backgroundColor,
        backgroundPosition: template.backgroundPosition,
        backgroundSize: template.backgroundSize || 'cover',
        colorPalette: template.colorPalette,
        fontSizes: template.fontSizes,
        height: template.height,
        width: template.width,
        widgets: [] as TemplateWidgetsModels[],
        suffix: template.suffix,
    } as TemplateSchemaModel;

    template.widgets.forEach(widget => {
        const baseModel = {
            id: widget.id || createHash(widget.label),
            label: widget.label,
            type: widget.type,
            zIndex: widget.zIndex,
            height: widget.height,
            width: widget.width,
            maxHeight: widget.maxHeight,
            top: widget.top,
            left: widget.left,
            right: widget.right,
            bottom: widget.bottom,
            defaultValue: widget.defaultValue,
            margins: getValuesPairFromCss(widget.margin),
            paddings: getValuesPairFromCss(widget.padding),
        } as WidgetBaseModel;

        if (widget.type === 'button' || widget.type === 'text') {
            const textProperties = {
                backgroundColor: widget.backgroundColor || 'transparent',
                color: widget.color || '',
                fontSize: widget.fontSize || 14,
                fontWeight: widget.fontWeight || 400,
                letterSpacing: parseNumber(widget.letterSpacing),
                lineHeight: parseNumber(widget.lineHeight),
                whiteSpace: widget.whiteSpace,
                align: ['center', 'center'],
            } as WidgetTextPropertiesModel;
            if (widget.type === 'button') {
                result.widgets.push(
                    Object.assign(baseModel, textProperties, {
                        backgroundColor: widget.backgroundColor,
                        border: getBorderModel(widget.border),
                        borderRadius: parseNumber(widget.borderRadius),
                        icon: {
                            defaultValue: widget.icon?.defaultValue,
                            left: widget.icon?.left,
                            top: widget.icon?.top,
                            fill: widget.icon?.fill,
                            width: widget.icon?.width,
                            height: widget.icon?.height,
                            marginLeft: widget.icon?.marginLeft,
                        },
                    } as Partial<WidgetButtonModel>) as WidgetButtonModel
                );
                return;
            }
            result.widgets.push(Object.assign(baseModel, textProperties) as TemplateWidgetsModels);
        } else {
            result.widgets.push({ ...baseModel } as TemplateWidgetsModels);
        }
    });
    return result;
}

export function schemaModelToTemplateSchema(template: TemplateSchemaModel): TemplateSchema {
    const result = {
        width: template.width,
        height: template.height,
        colorPalette: template.colorPalette,
        fontSizes: template.fontSizes,
        suffix: template.suffix,
        backgroundImage: template.backgroundImage,
        backgroundColor: template.backgroundColor,
        backgroundSize: template.backgroundSize,
        backgroundPosition: template.backgroundPosition,
        widgets: [] as TemplateWidgets[],
    };

    template.widgets.forEach(widget => {
        const widgetBase = {
            type: widget.type,
            id: widget.id,
            zIndex: widget.zIndex,
            top: widget.top,
            left: widget.left,
            width: widget.width,
            height: widget.height,
            maxHeight: widget.maxHeight,
            padding: `${widget.paddings[0]}px ${widget.paddings[1]}px`,
            margin: `${widget.margins[0]}px ${widget.margins[1]}px`,
            defaultValue: widget.defaultValue,
            label: widget.label,
            bottom: widget.bottom,
            right: widget.right,
        } as WidgetBase;

        if (widget.type === 'button' || widget.type === 'text') {
            const textProperties = {
                color: widget.color || '',
                backgroundColor: widget.backgroundColor || 'transparent',
                fontSize: widget.fontSize || 14,
                fontWeight: widget.fontWeight || 400,
                letterSpacing: addCssUnit(widget.letterSpacing, 'em'),
                lineHeight: addCssUnit(widget.lineHeight, 'em'),
                // whiteSpace: String(widget.whiteSpace),
                align: [`${widget.align[0]}`, `${widget.align[1]}`],
            } as TextProperties;

            if (widget.type === 'button') {
                result.widgets.push(
                    Object.assign(widgetBase, textProperties, {
                        backgroundColor: widget.backgroundColor,
                        border: `${widget.border.width}px solid ${widget.border.color}`,
                        borderRadius: addCssUnit(widget.borderRadius),
                        icon: {
                            defaultValue: widget.icon?.defaultValue || '',
                            top: widget.icon?.top || 0,
                            left: widget.icon?.left || 0,
                            fill: widget.icon?.fill || '',
                            width: widget.icon?.width,
                            height: widget.icon?.height,
                            marginLeft: widget.icon?.marginLeft,
                        },
                    } as Partial<ButtonWidget>) as ButtonWidget
                );
            } else {
                result.widgets.push(Object.assign(widgetBase, textProperties) as TextWidget);
            }
        } else {
            result.widgets.push({ ...widgetBase } as TemplateWidgets);
        }
    });

    return result;
}

export function modelToStyles<W extends TemplateWidgetsModels, R = Record<keyof WidgetBase, any>>(widget: W) {
    const result = modelToBaseStyles(widget);
    if (isButtonModel(widget) || isTextModel(widget)) {
        Object.assign(result, modelToTextStyles(widget));
    }
    if (isButtonModel(widget)) {
        Object.assign(result, {
            backgroundColor: widget.backgroundColor,
            border: `${widget.border.width}px solid ${widget.border.color}`,
            borderRadius: addCssUnit(widget.borderRadius),
            icon: {
                defaultValue: widget.icon?.defaultValue,
                top: valueOrAuto(widget.icon?.top),
                left: valueOrAuto(widget.icon?.left),
                width: valueOrAuto(widget.icon?.width),
                height: valueOrAuto(widget.icon?.height),
                marginLeft: widget.icon?.marginLeft && `${widget.icon.marginLeft}px`,
                fill: widget.icon?.fill,
            },
        });
    }

    if (widget.type === 'layer') {
        Object.assign(result, {
            width: widget.width ? `${widget.width}px` : '100%',
            height: widget.height ? `${widget.height}px` : '100%',
        });
    }
    return result as Record<keyof R, any>;
}
