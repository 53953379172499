





















import { Component, Prop, Vue } from 'vue-property-decorator';
import { Chrome } from 'vue-color';

@Component({
    components: {
        Chrome,
    },
})
export default class ColorPalette extends Vue {
    @Prop({ default: () => [] }) colors!: string[];
    @Prop({ default: 6 }) max!: number;
    selected = '#000';
    open = false;

    onSelect() {
        this.$emit('change', [...new Set(this.colors.concat(this.selected))]);
        this.selected = '#000';
        this.open = false;
    }

    onDelete(toDelete: string) {
        this.$emit('change', [...new Set(this.colors.filter((color) => color !== toDelete))]);
    }
}
