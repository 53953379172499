



















import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class PreviewScale extends Vue {
    @Prop() scaleValue!: number;

    initialValueScale = 1;
    stepScale = 0.1;
    minScale = 0.2;
    maxScale = 3;

    get scaleValuePercent() {
        return `${(this.scaleValue * 100).toFixed()}%`;
    }

    increment() {
        if (this.scaleValue > this.maxScale) return;
        const updateScaleValue = this.scaleValue + this.stepScale;
        this.$emit('change', updateScaleValue);
    }

    decrement() {
        if (this.scaleValue < this.minScale) return;
        const updateScaleValue = this.scaleValue - this.stepScale;
        this.$emit('change', updateScaleValue);
    }

    reset() {
        if (this.scaleValue !== this.initialValueScale) {
            this.$emit('change', this.initialValueScale);
        }
    }
}
