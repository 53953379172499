import { Module } from 'vuex';
import { ListPageState, ManageFamiliesListFilterParams, RequestForUpdateFamilyType, Sorting } from '@/shared/models';
import { MediaListFilters } from '@/modules/media/models';
import createPaginationModule from '@/store/modules/paginator';
import { manageFamiliesAdapter } from '@/services/api';
import { FamiliesType } from '@shared/models/FamiliesType';

export interface ManageFamiliesState {
    pg?: ListPageState<ManageFamiliesListFilterParams, FamiliesType[]>;
    filterParams: MediaListFilters;
}

const module: Module<ManageFamiliesState, RootState> = {
    namespaced: true,
    state: {
        filterParams: {
            dimensions: [],
            tags: [],
            sort: {},
        },
    },
    actions: {
        async add(_, data: RequestForUpdateFamilyType) {
            await manageFamiliesAdapter.updateFamily(data);
        },
        async delete(_, id: number) {
            await manageFamiliesAdapter.deleteFamily(id);
        },
    },
    mutations: {},
    modules: {
        pg: createPaginationModule<ManageFamiliesListFilterParams, FamiliesType[]>({
            api: manageFamiliesAdapter,
            initialState: () => ({
                page: {
                    size: 10,
                    index: 0,
                    rows: [],
                    totalItems: 0,
                },
                params: {
                    query: '',
                    sortAsc: false,
                    options: 2,
                    sort: Sorting.ByDate,
                    dimensions: [],
                    tags: [],
                },
                dataId: 1,
            }),
            infinite: false,
        }),
    },
};
export default module;
