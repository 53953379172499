

























































import store, { IconUploadData, ACTIONS, MUTATIONS } from './store';
import { IconsList } from './types';
import { IconType } from '../Editor/store';
import { PadinationLoadOptions, PAGINATION_ACTIONS, PAGINATION_MUTATIONS } from '@/store/modules/paginator';
import { Column } from 'ant-design-vue/types/table/column';
import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { debounce } from '@/shared/utils';
import { FamilyType, ListFilterParams } from '@/shared/models';
import UploadModal from './UploadModal.vue';

const ns = namespace('admin.icons');
const pg = namespace('admin.icons/pg');

@Component({
    created() {
        this.$registerVuexModule('admin.icons', store);
    },
    components: {
        uploadModal: UploadModal,
    },
})
export default class Icons extends Vue {
    @pg.State
    public params!: ListFilterParams;
    @pg.Getter
    public pageSize!: number;
    @pg.Getter
    public total!: number;
    @pg.Getter
    public pageIndex!: number;
    @pg.Getter
    public pageItems!: IconsList[];
    @pg.State
    public loading!: boolean;

    @ns.Action('delete')
    public delete!: (payload: string) => Promise<void>;

    @ns.Action('upload')
    public upload!: ({ file, purpose: number }: IconUploadData) => Promise<void>;
    @ns.Action('getFamilies')
    public getFamilies!: (payload: Partial<ListFilterParams>) => Promise<void>;

    @pg.Action(PAGINATION_ACTIONS.load)
    public loadPage!: (options?: PadinationLoadOptions) => Promise<boolean>;

    @ns.Action(ACTIONS.loadFilterParams) loadFilterParams!: () => void;

    @pg.Mutation(PAGINATION_MUTATIONS.setParams)
    public setParams!: (params: Partial<ListFilterParams>) => void;
    @pg.Mutation(PAGINATION_MUTATIONS.resetData) resetData!: () => void;

    Families = FamilyType;
    visible = false;
    selectedType = IconType.logo;
    showUploadModal = false;

    destroyed() {
        this.resetData();
    }

    mounted() {
        this.loadPage();
        this.getFamilies({ options: FamilyType.IconFamily });
        this.loadFilterParams();
    }

    async afterIconUploaded() {
        this.loadPage({ page: this.pageIndex });
    }

    async deleteIcon(id: string) {
        await this.delete(id);
        this.loadPage({ page: this.pageIndex });
    }

    onTableParamsChanged(paging: { current: number }) {
        this.loadPage({ page: paging.current - 1 });
    }

    iconTypeChanged(options: number) {
        this.updateParams({ options });
    }

    onSearch(value: Event | string) {
        this.updateParams({ query: typeof value === 'string' ? value : (value.target as HTMLInputElement).value });
    }

    updateParams(params: Partial<ListFilterParams>) {
        this.setParams(params);
        this.debouncedLoad();
    }

    debouncedLoad = debounce(() => this.loadPage({ page: 0 }), 500);

    columns: Partial<Column>[] = [
        { title: 'Family', dataIndex: 'family.name', scopedSlots: { customRender: 'family.name' } },
        { title: 'Name', key: 'name', scopedSlots: { customRender: 'name' } },
        { title: 'Url', key: 'id', dataIndex: 'defaultUrl', scopedSlots: { customRender: 'url' } },
        { title: 'Preview', dataIndex: 'defaultUrl', scopedSlots: { customRender: 'preview' } },
        { title: 'Action', key: 'action', dataIndex: 'id', scopedSlots: { customRender: 'action' } },
    ];
}
