














import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import TemplateForm from './components/commonForms/TemplateForm.vue';
import WidgetsList from './components/WidgetsList.vue';
import TemplatePreview from './components/TemplatePreview.vue';
import PropertiesList from './components/WidgetForm.vue';
import store, { EDITOR_ACTIONS, EDITOR_MUTATIONS } from './store';
import { namespace } from 'vuex-class';
import { templateSchemaToSchemaModel } from './utils';
import { TemplateSchema, TemplateSchemaModel } from '@/packages/template-renderer/types/Template';
import TemplatesApiAdapter from '@/services/api/adapters/TemplatesApiAdapter';
import { Template } from '@/shared/models/Template';
import TemplateAdminApiAdapter from '@/services/api/adapters/admin/TemplateAdminApiAdapter';
import { TemplateWidgetsModels } from '@/packages/template-renderer/types/Widget';
import PreviewScale from './components/PreviewScale.vue';
import {FamilyType, ListFilterParams} from "@models";

const ns = namespace('admin.editor');
const DEFAULT_FONT_SIZE = 12;

@Component({
    components: {
        WidgetsList,
        TemplateForm,
        TemplatePreview,
        PropertiesList,
        PreviewScale,
    },
    created() {
        this.$registerVuexModule('admin.editor', store);
    },
})
export default class Editor extends Vue {
    @ns.Action(EDITOR_ACTIONS.getTemplateFamilies) getTemplateFamilies!: (payload: Partial<ListFilterParams>) => Promise<string[]>;
    @ns.Action(EDITOR_ACTIONS.getBackgroundsFilters) getBackgroundsFilters!: () => Promise<void>;
    @ns.Mutation setBackgroundId!: (id: number) => void;
    @ns.Mutation(EDITOR_MUTATIONS.useSchema) useSchema!: (payload: { model: TemplateSchemaModel; template: Template }) => void;
    @ns.Mutation(EDITOR_MUTATIONS.reset) reset!: () => void;
    @ns.Mutation(EDITOR_MUTATIONS.setEditType) setEditType!: (type: string) => void;
    @ns.Mutation(EDITOR_MUTATIONS.setTemplateId) setTemplateId!: (templateId: number) => void;
    @ns.Mutation(EDITOR_MUTATIONS.updateTemplateSchema) updateSchema!: (payload: Partial<TemplateSchema>) => void;
    @ns.Mutation(EDITOR_MUTATIONS.updateScaleValue) updateScaleValue!: (scaleValue: number) => void;
    @ns.State scaleValue!: number;
    @ns.State listCreatedWidgets!: TemplateWidgetsModels[];
    @Prop() id!: string;
    @Prop() clone!: boolean;
    @Prop() edit!: boolean;

    @Watch('id')
    onUpdateMode(currentId: string, prevId: string) {
        if (prevId) this.setModeForEditor();
    }

    async mounted() {
        await this.getTemplateFamilies({ options: FamilyType.TemplateCollection });
        this.getBackgroundsFilters();
        this.setModeForEditor();
        if (this.id !== 'new') {
            try {
                if (this.clone) {
                    const template = await TemplateAdminApiAdapter.cloneTemplate(Number(this.id));
                    if (template) {
                        const model = templateSchemaToSchemaModel(template.contentSchema);
                        // if (model.fontSizes.length === 0) model.fontSizes = this.initializeFontSize(model);
                        this.useSchema({ model, template });
                    }
                } else {
                    const templates = await TemplatesApiAdapter.getTemplatesWithScheme([Number(this.id)]);
                    if (templates && templates[0]) {
                        const model = templateSchemaToSchemaModel(templates[0].contentSchema);
                        // if (model.fontSizes.length === 0) model.fontSizes = this.initializeFontSize(model);
                        this.useSchema({ model, template: templates[0] });
                    }
                }
            } catch (error) {
                // todo
            }
        }
    }

    initializeFontSize(model: TemplateSchemaModel) {
        const fontSizes = new Set();
        fontSizes.add(DEFAULT_FONT_SIZE);

        if (model.widgets.length) {
            model.widgets.forEach((widget: any) => {
                if (widget.hasOwnProperty('fontSize')) fontSizes.add(Math.floor(widget.fontSize));
            });
        }

        return Array.from(fontSizes) as number[];
    }

    onChangeScaleValue(value: number) {
        this.updateScaleValue(value);
    }

    get scaleValuePercent() {
        return `${(this.scaleValue * 100).toFixed()}%`;
    }

    setModeForEditor() {
        const isValidId = !isNaN(parseFloat(this.id));

        if (this.id === 'new') this.setEditType('new');
        if (isValidId && this.clone) {
            this.setEditType('clone');
            this.setTemplateId(0);
        }
        if (isValidId && this.edit) {
            this.setEditType('edit');
            this.setTemplateId(Number(this.id));
        }
    }

    destroyed() {
        this.reset();
    }
}
