




















































































import { Component, Vue } from 'vue-property-decorator';
import { EDITOR_MUTATIONS, Icons, UpdateTemplateSchema, UpdateWidget } from '@modules/admin/Editor/store';
import { namespace } from 'vuex-class';
import { TemplateSchema } from '@/packages/template-renderer/types/Template';
import { WidgetButtonModel } from '@/packages/template-renderer/types/Widget';
import { BaseWidgetForm, TypographyWidgetForm } from '../commonForms';
import InputColorPicker from '../InputColorPicker.vue';
import PickLogo from '@modules/editor/components/PickLogo.vue';
import CssInputNumber from '../CssInputNumber.vue';
import ImageUploader from '../ImageUploader.vue';
import { TemplateField } from '@/modules/editor/models/TemplateFields';

const ns = namespace('admin.editor');

@Component({
    components: {
        CssInputNumber,
        BaseWidgetForm,
        TypographyWidgetForm,
        PickLogo,
        InputColorPicker,
        ImageUploader,
    },
})
export default class PropertiesListButton extends Vue {
    @ns.Getter activeWidget!: WidgetButtonModel;
    @ns.Mutation(EDITOR_MUTATIONS.updateWidgetProperties)
    updateWidgetProperties!: ({ key: value }: UpdateWidget) => void;
    @ns.Mutation(EDITOR_MUTATIONS.updateTemplateSchema)
    updateTemplateSchema!: ({ propertyName, propertyValue }: UpdateTemplateSchema) => void;
    @ns.State icons!: Icons;
    @ns.State templateSchema!: TemplateSchema;

    get model() {
        return { value: this.activeWidget.icon?.defaultValue };
    }

    get iconModel() {
        return { value: this.activeWidget.icon?.defaultValue || '' };
    }

    get iconValue() {
        return this.activeWidget.icon?.defaultValue;
    }

    get iconSchema() {
        return {
            type: 'icon',
            label: '',
            order: this.activeWidget.zIndex,
        } as Partial<TemplateField>;
    }

    handleUpdateModel(name: string, value: string | number) {
        this.updateWidgetProperties({ ['icon']: { ...this.activeWidget?.icon, [name]: value } });
    }
}
