import { Module } from 'vuex';
import { ListPageState, ListFilterParams } from '@/shared/models';
import { UserActionPreview } from './models';
import createPaginationModule from '@/store/modules/paginator';
import { logsAdapter } from '@/services/api';

export interface LogsState {
    pg?: ListPageState<ListFilterParams, UserActionPreview>;
}

const module: Module<LogsState, RootState> = {
    namespaced: true,
    state: {},
    modules: {
        pg: createPaginationModule<ListFilterParams, UserActionPreview>({
            api: logsAdapter,
            initialState: () => ({
                page: {
                    size: 10,
                    index: 0,
                    rows: [],
                    totalItems: 0,
                },
                params: {
                    query: '',
                    sort: 0,
                    sortAsc: false,
                },
                dataId: 1,
            }),
            infinite: false,
        }),
    },
};
export default module;
