var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"boxTemplate",staticClass:"template-preview",style:({
        transform: _vm.getSaleValue,
        transformOrigin: 'left top',
        width: ((_vm.templateSchema.width) + "px"),
        height: ((_vm.templateSchema.height) + "px"),
        backgroundSize: _vm.templateSchema.backgroundSize,
        backgroundRepeat: 'no-repeat',
        backgroundImage: ("url('/assets/" + (encodeURI(_vm.templateSchema.backgroundImage)) + "')"),
        backgroundPosition: _vm.templateSchema.backgroundPosition,
        backgroundColor: _vm.templateSchema.backgroundColor,
        fontSize: _vm.templateSchema.fontSizes,
    }),on:{"mousedown":_vm.onMouseDown}},[_vm._l((_vm.listCreatedWidgets),function(widget){return [(widget.type === 'icon')?_c('icon-widget',{key:widget.id,attrs:{"properties":widget}}):(widget.type === 'button')?_c('button-widget',{key:widget.id,attrs:{"properties":widget}}):(widget.type === 'text')?_c('text-widget',{key:widget.id,attrs:{"properties":widget}}):(widget.type === 'layer')?_c('layer-widget',{key:widget.id,attrs:{"properties":widget}}):_vm._e()]})],2)}
var staticRenderFns = []

export { render, staticRenderFns }