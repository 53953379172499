




import { WidgetButtonModel, ButtonWidget } from '@/packages/template-renderer/types/Widget';
import { Component, Prop, Vue } from 'vue-property-decorator';
import IconWidget from './IconWidget.vue';
import { modelToStyles } from '../utils';
import { ButtonTemplate } from '@/packages/template-renderer/components';
@Component({
    components: {
        IconWidget,
        ButtonTemplate,
    },
})
export default class ButtonPreview extends Vue {
    @Prop() properties!: WidgetButtonModel;

    get styles() {
        return modelToStyles<WidgetButtonModel>(this.properties) as Record<keyof ButtonWidget, any>;
    }

    get icon() {
        const { defaultValue, ...styles } = this.styles.icon || {};
        return { styles, value: this.$options.filters?.assetsUrl(defaultValue) };
    }
}
