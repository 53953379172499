




import { WidgetTextModel } from '@/packages/template-renderer/types/Widget';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { modelToStyles } from '@modules/admin/Editor/utils';
import { TextTemplate } from '@/packages/template-renderer/components';

@Component({
    components: { TextTemplate },
})
export default class TemplatePreview extends Vue {
    @Prop() properties!: WidgetTextModel;

    get style() {
        return modelToStyles(this.properties);
    }
}
