



























































import { Component, Ref, Vue } from 'vue-property-decorator';
import store, { EDITOR_MUTATIONS, ListWidgetTypes } from '../store';
import { namespace } from 'vuex-class';
import { getButtonWidgetStyles, getIconWidgetStyles, getLayerWidgetStyles, getTextWidgetStyles } from '../models';
import { TemplateWidgetsModels } from '@/packages/template-renderer/types/Widget';
import {FamiliesType} from "@shared/models/FamiliesType";

const ns = namespace('admin.editor');

@Component({
    created() {
        this.$registerVuexModule('admin.editor', store);
    },
})
export default class CreateWidget extends Vue {
    @Ref('selectWidget') readonly selectWidgetRef!: HTMLSelectElement;
    @ns.Mutation(EDITOR_MUTATIONS.addWidget) addWidget!: (widget: TemplateWidgetsModels) => void;
    @ns.Mutation(EDITOR_MUTATIONS.deleteWidget) deleteWidget!: (id: number) => void;
    @ns.Mutation(EDITOR_MUTATIONS.setActiveWidget) setActiveWidget!: (id: number) => void;
    @ns.State listWidgetTypes!: ListWidgetTypes[];
    @ns.State listCreatedWidgets!: TemplateWidgetsModels[];
    @ns.Getter activeWidget!: TemplateWidgetsModels;

    labelError = '';
    label = '';
    selectedWidget = '';

    handlerEnterLabel(e: Event) {
        this.label = (e.target as HTMLInputElement).value?.trim() || '';
        if (this.listCreatedWidgets.some((widget) => widget.label === this.label)) {
            this.labelError = 'Already have widget with that label';
        } else {
            this.labelError = '';
        }
    }

    handlerAddWidget() {
        if (!this.selectedWidget) return;
        switch (this.selectedWidget) {
            case 'icon':
                this.addWidget(getIconWidgetStyles(this.label));
                break;
            case 'button':
                this.addWidget(getButtonWidgetStyles(this.label));
                break;
            case 'text':
                this.addWidget(getTextWidgetStyles(this.label));
                break;
            case 'layer':
                this.addWidget(getLayerWidgetStyles(this.label));
                break;
        }
        this.label = '';
        this.selectedWidget = '';
    }

    handlerSetActiveWidget(id: number) {
        this.setActiveWidget(id);
    }

    handlerDeleteWidget(id: number) {
        this.deleteWidget(id);
    }
}
