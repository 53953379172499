import { MutationTree } from 'vuex';
import { UploadCropState } from './index';

const mutations: MutationTree<UploadCropState> = {
    setFile(state, file: File | null) {
        state.file = file;
        if (!file) {
            state.cropped = null;
            state.stages;
        }
    },
    setCropped(state, blob: Blob | null) {
        state.cropped = blob;
    },
    go(state, stage: number) {
        state.stages.forEach(s => (s.active = stage === s.id ? true : false));
    },
    reset(state) {
        state.file = state.cropped = null;
        state.stages.forEach((s, i) => {
            s.active = i === 0 ? true : false;
        });
    },
};
export default mutations;
