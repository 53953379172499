import { GetterTree } from 'vuex';
import { UploadCropState } from '.';

const getters: GetterTree<UploadCropState, RootState> = {
    activeStage(state) {
        return state.stages.find(s => s.active)?.id;
    },
    file(state) {
        return state.file;
    },
    stageFile(state, getters) {
        return getters.activeStage === 1 ? state.file : state.cropped || state.file;
    },
    cropped(state) {
        return state.cropped;
    },
};

export default getters;
