

















































import { BackgroundPreview } from '@/modules/media/models';
import { UploadParams } from '@/services/api/adapters/MediaApiAdapters';
import { FormModel } from 'ant-design-vue';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Ref } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import CollectionName from '../components/CollectionName.vue';
import { FamiliesType } from '@shared/models/FamiliesType';
import { FamilyType, RequestForUpdateFamilyType } from '@/shared/models';

const ns = namespace('admin.backgrounds');
const ONE_KILOBYTE = 1024;
const MAX_SIZE_BACKGROUND_IMAGE = 10 * ONE_KILOBYTE;

@Component({
    model: {
        prop: 'visible',
        event: 'change',
    },
    components: {
        CollectionName: CollectionName,
    },
})
export default class extends Vue {
    @Prop({ default: false }) visible!: boolean;
    @ns.State
    public families!: FamiliesType[];
    @ns.Action('upload') upload!: (params: UploadParams) => Promise<{ backgrounds: BackgroundPreview[] }>;
    @ns.Action('addFamily') addFamily!: (params: RequestForUpdateFamilyType) => Promise<void>;

    FamilyType = FamilyType;
    uploading = false;
    uploadingNewFamily = false;
    model = {
        customNames: '',
        family: undefined as string | undefined,
        fileList: [] as File[],
    };

    rules = {
        family: { required: true, message: 'Family is required' },
        fileList: { type: 'array', required: true, message: 'Select file to upload' },
        customNames: { required: true, message: 'Name is required' },
    };

    @Ref('form') form!: FormModel;

    fileList: Blob[] = [];

    get tags() {
        return this.families?.map(family => family.name);
    }
    get isValidFileSize() {
        return ((this.model.fileList[0]?.size/ONE_KILOBYTE) < MAX_SIZE_BACKGROUND_IMAGE);
    }
    get disabledUploadButton() {
        return (this.uploading || !this.isValidFileSize || !this.model.customNames || !this.model.family || !this.model.fileList.length);
    }
    onChange(e: any) {
        this.model.customNames = e.target.value;
    }
    async onAdd(familyName: string) {
        const requestData = {
            family: {
                id: null,
                familyType: this.FamilyType.BackgroundFamily,
                name: familyName,
            },
        };
        try {
            this.uploadingNewFamily = true;
            await this.addFamily(requestData);
            this.model.family = familyName;
        } catch (err) {
            // Todo
        } finally {
            this.uploadingNewFamily = false;
        }
    }
    beforeUpload(file: File) {
        this.model.fileList = [file];
        return false;
    }
    handleRemove() {
        this.model.fileList = [];
    }
    select(family: string) {
        this.model.family = family;
        // this.form.validate(); why it doesnt happend automatically?
    }
    reset() {
        this.form.resetFields();
    }
    getFamilyId(family: string) {
        return this.families.find(f => f.name === family)?.id || 0;
    }
    async uploadIcon() {
        this.form.validate(async valid => {
            if (valid) {
                this.uploading = true;
                try {
                    const res = await this.upload({
                        file: this.model.fileList[0],
                        familyId: this.getFamilyId(this.model.family || ''),
                        customNames: this.model.customNames,
                    });
                    this.reset();
                    this.$emit('change', false);
                    this.$emit('success', res.backgrounds[0]);
                } catch (error) {
                    console.error(error);
                }
                this.uploading = false;
            }
        });
    }
}
