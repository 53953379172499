


































































import { Component, Vue, Watch } from 'vue-property-decorator';
import {EDITOR_ACTIONS, EDITOR_MUTATIONS} from '../../store';
import { namespace } from 'vuex-class';
import { TemplateSchema, TemplateSchemaModel } from '@/packages/template-renderer/types/Template';
import BackgroundSelector from '../BackgroundSelector.vue';
import CssInputNumber from '../CssInputNumber.vue';
import CollectionName from '../../../components/CollectionName.vue';
import { schemaModelToTemplateSchema } from '../../utils';
import { TemplateWidgetsModels } from '@/packages/template-renderer/types/Widget';
import { SnotifyPosition } from 'vue-snotify';
import ColorPalette from '../ColorPalette.vue';
import { FamiliesType } from '@shared/models/FamiliesType';
import {FamilyType, ListFilterParams, RequestForUpdateFamilyType} from '@/shared/models';

const ns = namespace('admin.editor');
const MAX_NUMBER_CHARACTERS_IN_FONT_SIZE = 3;

@Component({
    components: {
        BackgroundSelector,
        CssInputNumber,
        TemplateColorPalette: ColorPalette,
        CollectionName,
    },
    data() {
        return {
            collectionName: undefined,
        };
    },
})
export default class EditorHeader extends Vue {
    @ns.State templateFamilies!: FamiliesType[];
    @ns.State templateSchema!: TemplateSchemaModel;
    @ns.State listCreatedWidgets!: TemplateWidgetsModels[];
    @ns.State templateName!: string;
    @ns.State templateTag!: string;
    @ns.State editType!: string;
    @ns.Action saveTemplate!: (validSchema: TemplateSchema) => Promise<{ templateId: number }>;
    @ns.Action addFamily!: (params: RequestForUpdateFamilyType) => Promise<void>;
    @ns.Mutation(EDITOR_MUTATIONS.setTemplateName) setTemplateName!: (templateName: string) => void;
    @ns.Mutation(EDITOR_MUTATIONS.setTemplateTag) setTemplateTag!: (templateTag: string) => void;
    @ns.Mutation(EDITOR_MUTATIONS.setFamilyId) setFamilyId!: (templateTag: number) => void;
    @ns.Mutation(EDITOR_MUTATIONS.updateTemplateSchema) updateSchema!: (payload: Partial<TemplateSchema>) => void;

    isDisableSave = !this.templateName;
    isErrorEmptyInput = false;
    modalVisibility = false;
    uploading = false;
    FamilyType = FamilyType;

    @Watch('templateName')
    setDisableSave() {
        this.isDisableSave = !this.templateName;
    }

    get preparedTags() {
        return this.templateFamilies.map(tag => tag.name) || [];
    }

    showConfirm() {
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const self = this;
        this.$confirm({
            title: 'Are you sure you want to save?',
            content: '',
            onOk() {
                self.handleSaveTemplate();
            },
        });
    }

    onFontInputKeydown(e: KeyboardEvent) {
        if (
            !(
                ['Backspace', 'Delete', 'Del', 'Enter'].includes(e.key) ||
                (/\d+/.test(e.key) && (e.key + (e.target as HTMLInputElement).value).length <= MAX_NUMBER_CHARACTERS_IN_FONT_SIZE)
            )
        ) {
            e.preventDefault();
            e.stopPropagation();
        }
    }

    async handleSaveTemplate() {
        const templateSchemaModel = Object.assign(this.templateSchema, { widgets: this.listCreatedWidgets });
        const validSchema = schemaModelToTemplateSchema(templateSchemaModel);
        const text = `Success ${this.editType === 'edit' ? 'update' : 'create'} template`;
        try {
            const idSaveTemplate = await this.saveTemplate(validSchema);
            this.$snotify.success(text, { position: SnotifyPosition.rightTop });
            if (this.editType === 'new' || this.editType === 'clone') this.openNewTemplate(idSaveTemplate.templateId);
        } catch (error) {
            throw error;
        }
    }

    openNewTemplate(id: number) {
        this.$router.push({
            name: 'admin-editor',
            params: { id: String(id) },
            query: { edit: 'true' },
        });
    }

    formatter(value: number) {
        return `${value}px`;
    }
    parser(value: string) {
        return value.replace(/\$\s?/g, '');
    }

    handleSetTemplateTitle(e: any) {
        this.setTemplateName(e.target.value);
    }

    handleFocusTemplateTitle() {
        if (this.isErrorEmptyInput) this.isErrorEmptyInput = false;
    }

    async onAdd(familyName: string) {
        const requestData = {
            family: {
                id: null,
                familyType: this.FamilyType.TemplateCollection,
                name: familyName,
            },
        };
        try {
            this.uploading = true;
            await this.addFamily(requestData);
            this.setTemplateTag(familyName);
            this.onSelect(familyName)
        } catch (err) {
            // Todo
        } finally {
            this.uploading = false;
        }
    }

    onSelect(value: string) {
        const familyId = this.templateFamilies.find(tag => tag.name === value)?.id || 0;
        this.setTemplateTag(value);
        this.setFamilyId(familyId);
        if (this.templateName) {
            const templateName = `${this.templateName.split('-')[0]}-${value}`;
            this.setTemplateName(templateName);
        }
    }
}
