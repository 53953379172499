




import { Prop, Vue, Component } from 'vue-property-decorator';

@Component
export default class CssInputNumber extends Vue {
    @Prop({ default: 'px' }) suffix!: string;

    formatter(value: number) {
        return `${value}${this.suffix}`;
    }
    parser(value: string) {
        return value.replace(/([^\d\.])+/g, '');
    }
}
