import {
    WidgetBaseModel,
    WidgetButtonModel,
    WidgetIconModel,
    WidgetLayerModel,
    WidgetTextModel,
    WidgetTextPropertiesModel,
} from '@/packages/template-renderer/types/Widget';
import { createHash } from '@/shared/utils';

export function getBaseWidgetStyles(label: string): WidgetBaseModel {
    return {
        id: createHash(label),
        label,
        width: 100,
        height: 100,
        top: 0,
        left: 0,
        zIndex: 0,
        defaultValue: '',
        paddings: [0, 0],
        bottom: 0,
        right: 0,
        margins: [0, 0],
        maxHeight: 0,
        type: '',
    };
}

export function getTextPropertiesStyles(): WidgetTextPropertiesModel {
    return {
        fontSize: 14,
        fontWeight: 400,
        lineHeight: 1.25,
        letterSpacing: 0,
        whiteSpace: 'pre-wrap',
        align: ['center', 'center'],
        color: '',
        backgroundColor: '',
    };
}

export function getIconWidgetStyles(label: string): WidgetIconModel {
    return {
        ...getBaseWidgetStyles(label),
        type: 'icon',
        fill: '',
        defaultValue: '',
    };
}
export function getButtonWidgetStyles(label: string): WidgetButtonModel {
    const base = getBaseWidgetStyles(label);
    return {
        ...base,
        ...getTextPropertiesStyles(),
        type: 'button',
        border: {
            width: 1,
            color: '#000',
        },
        borderRadius: 0,
        icon: {
            defaultValue: '',
            left: 0,
            top: 0,
            width: base.width,
            height: base.height,
            marginLeft: 7,
        },
    };
}

export function getLayerWidgetStyles(label: string): WidgetLayerModel {
    return {
        ...getBaseWidgetStyles(label),
        type: 'layer',
        defaultValue: '',
    };
}

export function getTextWidgetStyles(label: string): WidgetTextModel {
    return {
        ...getBaseWidgetStyles(label),
        ...getTextPropertiesStyles(),
        type: 'text',
        color: '#000',
    };
}
