import {FamilyType, ListFilterParams, ListPageState, RequestForUpdateFamilyType} from '@/shared/models';
import createPaginationModule from '@/store/modules/paginator';
import {iconsAdapter, manageFamiliesAdapter, templatesAdminAdapter} from '@/services/api';
import { Module } from 'vuex';
import createUploaderModule, { UploadCropState } from '@store/modules/uploadCrop';
import { IconsFilterParams, IconsList, IconUploadRequestData } from './types';
import { FamiliesType } from '@shared/models/FamiliesType';

export const MUTATIONS = {
    setFilterParams: 'setFilterParams',
    updateFilterParams: 'updateFilterParams',
    setFamilies: 'setFamilies',
};

export const ACTIONS = {
    loadFilterParams: 'loadFilterParams',
    delete: 'delete',
    upload: 'upload',
};

export interface IconsState {
    uploader?: UploadCropState;
    pg?: ListPageState<ListFilterParams, IconsList>;
    filterParams?: IconsFilterParams;
    families: FamiliesType[];
}

export type IconUploadData = Pick<IconUploadRequestData, 'file' | 'purpose' | 'familyId'>;

const module: Module<IconsState, RootState> = {
    namespaced: true,
    state: {
        filterParams: undefined,
        families: [],
    },
    actions: {
        async upload(_, { file, purpose, familyId }: IconUploadData) {
            try {
                if (file.name) {
                    await iconsAdapter.upload({ file, purpose, isTemp: true, familyId });
                }
            } catch (error) {
                throw error;
            }
        },
        async delete(_, id: string) {
            try {
                await iconsAdapter.delete(id);
            } catch (error) {
                throw error;
            }
        },
        async addFamily({dispatch}, data: RequestForUpdateFamilyType) {
            await manageFamiliesAdapter.updateFamily(data);
            await dispatch('getFamilies', { options: data.family.familyType });
        },
        async getFamilies({ commit }, params: Partial<ListFilterParams>) {
            commit('setFamilies', await templatesAdminAdapter.getFamilies(params));
        },
        async [ACTIONS.loadFilterParams]({ commit }) {
            try {
                const params = await iconsAdapter.getFilterParams();
                commit(MUTATIONS.setFilterParams, params);
            } catch (error) {}
        },
    },
    mutations: {
        [MUTATIONS.setFilterParams](state, params: IconsFilterParams) {
            state.filterParams = params;
        },
        [MUTATIONS.setFamilies](state, payload: FamiliesType[]) {
            state.families = payload;
        },
        [MUTATIONS.updateFilterParams](state, params: { type: number; value: string }) {
            if (state.filterParams?.familiesByPurpose[params.type] && !state.filterParams.allFamilies.includes(params.value)) {
                state.filterParams.allFamilies.push(params.value);
                state.filterParams.familiesByPurpose[params.type].push(params.value);
            }
        },
    },
    getters: {
        filterParams(state) {
            return state.filterParams;
        },
    },
    modules: {
        pg: createPaginationModule<ListFilterParams, IconsList>({
            api: iconsAdapter,
            initialState: () => ({
                page: {
                    size: 10,
                    index: 0,
                    rows: [],
                    totalItems: 0,
                },
                params: {
                    query: '',
                    sort: 0,
                    sortAsc: true,
                    options: 0,
                    familyIds: [],
                },
                dataId: 1,
            }),
            infinite: false,
        }),
        uploader: createUploaderModule(),
    },
};

export default module;
