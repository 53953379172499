








import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { ButtonWidgetForm, IconWidgetForm, LayerWidgetForm, TextWidgetForm } from './widgetForms';
import { TemplateWidgets, TemplateWidgetTypes } from '@/packages/template-renderer/types/Widget';
import { VueConstructor } from 'vue';
import { IconType } from "@modules/admin/Editor/store";

const ns = namespace('admin.editor');

const componentsByType: Record<TemplateWidgetTypes, VueConstructor> = {
    icon: IconWidgetForm,
    text: TextWidgetForm,
    button: ButtonWidgetForm,
    layer: LayerWidgetForm,
};

@Component
export default class PropertiesList extends Vue {
    @ns.Getter activeWidget!: TemplateWidgets;
    @ns.Action getIcons!: (type: IconType) => void;

    get component() {
        return componentsByType[this.activeWidget.type];
    }

    mounted() {
        this.getIcons(IconType.button);
        this.getIcons(IconType.logo);
    }
}
