






































































import { BackgroundPreview } from '@/modules/media/models';
import { Component, Prop, Vue } from 'vue-property-decorator';
import CropperModal from '@components/Modal/CropperModal.vue';
import { namespace } from 'vuex-class';
import CssInputNumber from './CssInputNumber.vue';
import { EDITOR_MUTATIONS, Errors, UpdateError } from '../store';
import { getFileByUrl } from '@shared/utils';
import { ListFilterParams} from '@models';
import { FamiliesType } from '@shared/models/FamiliesType';

const ns = namespace('admin.editor');
const uns = namespace('admin.editor/uploader');

@Component({
    components: { CssInputNumber, CropperModal },
})
export default class BackgroundSelector extends Vue {
    @ns.State errors!: Errors;
    @ns.Mutation(EDITOR_MUTATIONS.updateError) updateError!: ({ errorProperty, errorValue }: UpdateError) => void;
    @Prop() value!: string;
    @Prop() position!: [number, number] | string;
    @Prop() size!: string;
    listBackgroundSize = ['cover', 'auto', 'contain'];
    selectedTags: string[] = [];

    positionOptions = [
        'left top',
        'center top',
        'right top',
        'left center',
        'center center',
        'right center',
        'left bottom',
        'center bottom',
        'right bottom',
    ];

    @ns.State listBackgrounds!: BackgroundPreview[];
    @ns.State cropperHistory!: BackgroundPreview[];
    @ns.State bgFamilies!: FamiliesType[];
    @ns.Action('getBackgroundFamilies') getBackgroundFamilies!: () => void;
    @ns.Mutation setBackgroundId!: (id: number) => void;
    @ns.Mutation updateCropperHistory!: (item: BackgroundPreview[]) => void;
    @ns.Action getBackgrounds!: (params?: Partial<ListFilterParams>) => void;
    @uns.Getter('stageFile') uploaderFile!: File;
    modalVisibility = false;
    selectedBackground!: BackgroundPreview | null;
    bgFamilyId = 0;

    get tags() {
        return this.bgFamilies?.map(tag => ({ value: tag.name, label: tag.name }));
    }

    mounted() {
        this.getBackgrounds();
        this.getBackgroundFamilies();
    }

    onSelect(tag: string) {
        const tagId = this.bgFamilies?.find(t => t.name === tag)?.id || 0;
        this.getBackgrounds({ familyIds: tagId ? [tagId] : [] });
    }

    onChangeButtonClick() {
        if (this.value) {
            this.changeBackground();
        } else {
            this.toggleModalVisibility(true);
        }
    }

    toggleModalVisibility(value: boolean) {
        this.updateError({ errorProperty: 'isErrorEmptyBackgroundImage', errorValue: false });
        this.modalVisibility = value;
    }

    async selectBackground(background: BackgroundPreview | null) {
        this.bgFamilyId = background?.family?.id || 0;
        this.selectedBackground = background;
        this.toggleModalVisibility(false);
        this.handleChanges('backgroundImage', background?.defaultUrl || '', background?.id || 0);
        if (background) {
            await this.setUploaderFile(background?.defaultUrl || '');
            this.showCropper();
        }
    }

    async changeBackground() {
        if (this.value) {
            await this.setUploaderFile(this.value);
        }
        this.showCropper();
    }

    async setUploaderFile(url: string) {
        await this.$store.commit('admin.editor/uploader/setFile', await getFileByUrl(url));
        this.$store.commit('admin.editor/uploader/go', 2);
    }

    handleChanges(property: string, value: string, id?: number) {
        const changes: Record<string, any> = {
            backgroundSize: this.size,
            backgroundPosition: this.position,
            backgroundImage: this.value,
            [property]: value,
        };

        if (id && this.selectedBackground) {
            changes.background = this.selectedBackground;
            this.setBackgroundId(this.selectedBackground.id);
        }
        if (property === 'backgroundImage') {
            this.modalVisibility = false;
        }
        this.$emit('change', changes);
    }

    showCropper() {
        this.$modal.show(
            CropperModal,
            {
                namespace: 'admin.editor/uploader',
                uploadAction: 'admin.editor/uploadBackground',
                bgFamilyId: this.bgFamilyId,
                resetAfterUpload: false,
                uploader: false,
                title: 'Crop',
                cropperHistory: [...this.cropperHistory],
            },
            {},
            {
                undo: (bg: BackgroundPreview) => {
                    this.updateCropperHistory(this.getSliceCropperHistory(bg));
                    this.setBackgroundId(bg.id);
                    this.$emit('change', {
                        backgroundImage: bg.defaultUrl,
                    });
                },
            }
        );
    }

    getSliceCropperHistory(bg: Partial<BackgroundPreview>) {
        const indexBg = this.cropperHistory?.findIndex(item => item.id === bg.id);
        return indexBg !== -1 ? this.cropperHistory?.slice(0, indexBg + 1) : [];
    }
}
