export interface WidgetBase<T = string, V = string> {
    type: T;
    id: number;
    zIndex?: number;
    top?: number;
    left?: number;
    width?: number;
    height?: number;
    maxHeight?: number;
    padding?: string;
    margin?: string;
    defaultValue?:V;

    // form field properties
    label: string;

    // need to handle or not?
    bottom?: number;
    right?: number;
}



export interface TextProperties {
    align?: [horizontal: string, vertical: string],
    color?: string;
    fontSize?: number;
    fontWeight?: number;
    lineHeight?: string;
    letterSpacing?: string;
    whiteSpace?: string;
    backgroundColor?: string;
}

interface IconProperties {
    fill?: string;
}

export interface IconWidget extends WidgetBase<'icon'>, IconProperties {
}

export interface TextWidget extends WidgetBase<'text'>, TextProperties {
}
export interface ButtonWidget extends WidgetBase<'button'>, TextProperties {
    color?: string;
    border?: string; /* {width}px {type = solid} {color} */
    borderRadius: string;
    icon?: {
        defaultValue: string,
        top: number,
        left: number,
        width: number,
        height: number,
        marginLeft: number,
        fill?: string,
    };
}

export type TemplateWidgets = IconWidget | TextWidget | ButtonWidget | LayerWidget;
export type TemplateWidgetTypes = TemplateWidgets['type'];


export interface WidgetBaseModel<T = string> extends Omit<Required<WidgetBase<T>>, 'padding' | 'margin' > {
    paddings: [number, number];
    margins: [number, number];
}

export interface WidgetTextPropertiesModel extends Omit<Required<TextProperties>, 'lineHeight' | 'letterSpacing' | 'whiteSpace'> {
    lineHeight: number;
    letterSpacing: number;
    whiteSpace: string;
}

export interface WidgetButtonModel extends WidgetBaseModel<'button'>, WidgetTextPropertiesModel {
    border: {
        width: number,
        color: string
    };
    borderRadius: number;
    icon?: ButtonWidget['icon'];
}

export interface WidgetIconModel extends WidgetBaseModel<'icon'> {
    fill: string;
}

export type WidgetTextModel = WidgetBaseModel<'text'> & WidgetTextPropertiesModel;
export type WidgetLayerModel = WidgetBaseModel<'layer'>;


export type LayerWidget = WidgetBase<'layer'>;

export type TemplateWidgetsModels = WidgetIconModel | WidgetButtonModel | WidgetTextModel | WidgetLayerModel;

export function isButtonModel(widget: TemplateWidgetsModels): widget is WidgetButtonModel {
    return widget.type === 'button';
}
export function isTextModel(widget: TemplateWidgetsModels): widget is WidgetButtonModel {
    return widget.type === 'text';
}