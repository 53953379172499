import { Module } from 'vuex';
import {
    ListPageState,
    BackgroundListFilterParams,
    Sorting,
    ListFilterParams,
    RequestForUpdateFamilyType, FamilyType
} from '@/shared/models';
import { BackgroundPreview, MediaListFilters } from '@/modules/media/models';
import createPaginationModule from '@/store/modules/paginator';
import { backgroundsAdapter, manageFamiliesAdapter, templatesAdminAdapter } from '@/services/api';
import {ChangeBackgroundParams, UploadParams} from '@/services/api/adapters/MediaApiAdapters';
import { FamiliesType } from '@shared/models/FamiliesType';

export interface BackgroundState {
    pg?: ListPageState<BackgroundListFilterParams, BackgroundPreview>;
    filterParams: MediaListFilters;
    families: FamiliesType[];
}

const module: Module<BackgroundState, RootState> = {
    namespaced: true,
    state: {
        filterParams: {
            dimensions: [],
            tags: [],
            sort: {},
        },
        families: [],
    },
    actions: {
        async upload(_, params: UploadParams) {
            if (params.file) {
                return await backgroundsAdapter.upload(params);
            }
        },
        async changeBackground(_, params: ChangeBackgroundParams) {
            return await backgroundsAdapter.changeBackground(params);
        },
        async delete(_, ids: number[]) {
            try {
                await backgroundsAdapter.delete(ids);
            } catch (error) {
                throw error;
            }
        },
        async addFamily({dispatch}, data: RequestForUpdateFamilyType) {
            await manageFamiliesAdapter.updateFamily(data);
            await dispatch('getFamilies', { options: FamilyType.BackgroundFamily });
        },
        async getFamilies({ commit }, params: Partial<ListFilterParams>) {
            commit('setFamilies', await templatesAdminAdapter.getFamilies(params));
        },
        async getFilterParams({ commit }) {
            commit('setFilters', await backgroundsAdapter.getFilters());
        },
    },
    mutations: {
        setFamilies(state, payload: FamiliesType[]) {
            state.families = payload;
        },
        setFilters(state, payload: MediaListFilters) {
            state.filterParams = payload;
        },
    },
    modules: {
        pg: createPaginationModule<BackgroundListFilterParams, BackgroundPreview>({
            api: backgroundsAdapter,
            initialState: () => ({
                page: {
                    size: 10,
                    index: 0,
                    rows: [],
                    totalItems: 0,
                },
                params: {
                    query: '',
                    sort: Sorting.ByDate,
                    sortAsc: false,
                    dimensions: [],
                    tags: [],
                    familyIds: [],
                },
                dataId: 1,
            }),
            infinite: false,
        }),
    },
};
export default module;
