






























































import store from './store';
import { PadinationLoadOptions, PAGINATION_ACTIONS, PAGINATION_MUTATIONS } from '@/store/modules/paginator';
import { Column } from 'ant-design-vue/types/table/column';
import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { TemplateListFilterParams, TemplatePreview } from '@/modules/templates/models';
import { debounce } from '@/shared/utils';
import { PageRequest, PageResult } from '@/shared/models/Pagination';
import { FamilyType, ListFilterParams } from '@models';
import { FamiliesType } from '@shared/models/FamiliesType';

const ns = namespace('admin.templates');
const pg = namespace('admin.templates/pg');

@Component<Templates>({
    created() {
        this.$registerVuexModule('admin.templates', store);
    },
})
export default class Templates extends Vue {
    @pg.Getter
    public total!: number;
    @pg.Getter
    public pageIndex!: number;
    @pg.Getter
    public pageItems!: TemplatePreview[];
    @pg.State
    public params!: TemplateListFilterParams;
    @pg.State
    public page!: PageResult<TemplatePreview>;
    @pg.State
    public loading!: boolean;
    @ns.State
    public families!: FamiliesType[];

    @ns.Action('getFamilies') getFamilies!: (payload: Partial<ListFilterParams>) => Promise<void>;

    @ns.Action('delete')
    public delete!: (ids?: number[]) => Promise<boolean>;

    @ns.Action('clone')
    public clone!: (id?: number) => Promise<boolean>;

    @pg.Action(PAGINATION_ACTIONS.load)
    public loadPage!: (options?: PadinationLoadOptions) => Promise<boolean>;

    @pg.Mutation(PAGINATION_MUTATIONS.setParams)
    public setParams!: (params: Partial<TemplateListFilterParams>) => void;

    @pg.Action(PAGINATION_ACTIONS.updatePageSize)
    public updatePageSize!: (size: number) => void;

    @pg.Mutation(PAGINATION_MUTATIONS.resetData) resetData!: () => void;
    listIdDisable: number[] = [];

    get tags() {
        return this.families?.map(family => ({ label: family.name, value: family.name, id: family.id }));
    }

    mounted() {
        this.getFamilies({ options: FamilyType.TemplateCollection });
        this.loadPage();
    }

    destroyed() {
        this.resetData();
    }

    addDisable(id: number) {
        if (!this.listIdDisable.some(item => item === id)) this.listIdDisable.push(id);
    }

    deleteDisable(id: number) {
        this.listIdDisable = this.listIdDisable.filter(item => item !== id);
    }

    isDisabled(id: number) {
        return this.listIdDisable.some(item => item === id);
    }

    handleCreateNewBanner() {
        this.$router.push({
            name: 'admin-editor',
            params: { id: 'new' },
        });
    }

    async deleteHandler(id: number) {
        this.addDisable(id);
        try {
            await this.delete([id]);
        } catch (error) {
            throw error;
        } finally {
            this.deleteDisable(id);
        }
        this.loadPage({ page: this.pageIndex });
    }

    async cloneHandler(id: number) {
        this.addDisable(id);
        try {
            await this.clone(id);
        } catch (error) {
            throw error;
        } finally {
            this.deleteDisable(id);
        }
        this.$router.push({
            name: 'admin-editor',
            params: { id: String(id) },
            query: { clone: 'true' },
        });
    }

    async editHandler(id: number) {
        this.$router.push({
            name: 'admin-editor',
            params: { id: String(id) },
            query: { edit: 'true' },
        });
    }

    onTableParamsChanged(paging: { current: number; pageSize: number }) {
        this.loadPage({ page: paging.current - 1 });
    }

    onSearch(value: string | Event) {
        this.setParams({ query: typeof value === 'string' ? value : (value.target as HTMLInputElement).value });
        this.debouncedLoad();
    }

    updateParams(tags: string[]) {
        const tagIds: number[] = [];
        tags.forEach(t => {
            const id = this.families.find(family => family.name === t)?.id || 0;
            tagIds.push(id);
        });
        this.setParams({ familyIds: tagIds });
        this.debouncedLoad();
    }

    debouncedLoad = debounce(() => {
        this.loadPage({ page: 0 });
    }, 500);

    columns: Partial<Column>[] = [
        { title: 'Family', dataIndex: 'family.name' },
        { title: 'Template Name', key: 'name', scopedSlots: { customRender: 'name' } },
        { title: 'Created', dataIndex: 'created', scopedSlots: { customRender: 'date' } },
        { title: 'Last Used', dataIndex: 'savedDt', scopedSlots: { customRender: 'date' } },
        { title: 'Preview', dataIndex: 'previewUrl', scopedSlots: { customRender: 'preview' } },
        { title: 'Action', dataIndex: 'id', scopedSlots: { customRender: 'action' } },
    ];
}
