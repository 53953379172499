







import { debounce } from '@/shared/utils';
import { Component, Prop, Vue } from 'vue-property-decorator';
import CssInputNumber from './CssInputNumber.vue';

@Component({
    components: {
        CssInputNumber,
    },
})
export default class InputColorPicker extends Vue {
    @Prop() value!: string;

    onChange = debounce((color: string) => {
        this.$emit('change', color);
    }, 50);
}
