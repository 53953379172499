import { ActionTree } from 'vuex';
import { UploadCropState } from '.';

const actions: ActionTree<UploadCropState, RootState> = {
    async afterSave({ commit, state }) {
        commit('setFile', null);
        commit('setCropped', null);
        commit('go', 1);
    },
};
export default actions;
