




import { WidgetLayerModel } from '@/packages/template-renderer/types/Widget';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { modelToStyles } from '@modules/admin/Editor/utils';
import { LayerTemplate } from '@/packages/template-renderer/components';

@Component({ components: { LayerTemplate } })
export default class LayerPreview extends Vue {
    @Prop() properties!: WidgetLayerModel;

    get style() {
        return {
            ...modelToStyles(this.properties),
            backgroundImage: `url('/assets/${encodeURI(this.properties.defaultValue)}')`,
            zIndex: 0,
        };
    }
}
