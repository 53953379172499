



























import { Component, Ref, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import IconWidget from '../widgets/IconWidget.vue';
import ButtonWidget from '../widgets/ButtonWidget.vue';
import TextWidget from '../widgets/TextWidget.vue';
import LayerWidget from '../widgets/LayerWidget.vue';
import { TemplateWidgets } from '@/packages/template-renderer/types/Widget';
import { TemplateSchema } from '@/packages/template-renderer/types/Template';
import { debounce } from '@/shared/utils';

const ns = namespace('admin.editor');

interface BackgroundPosition {
    backgroundPosition: number[] | string;
}

@Component({
    components: {
        IconWidget,
        ButtonWidget,
        TextWidget,
        LayerWidget,
    },
})
export default class TemplatePreview extends Vue {
    @ns.State listCreatedWidgets!: TemplateWidgets[];
    @ns.State templateSchema!: TemplateSchema;
    @ns.State scaleValue!: number;
    @ns.Mutation('updateTemplateSchema') updateTemplateSchema!: ({ backgroundPosition }: BackgroundPosition) => void;
    @Ref('boxTemplate') boxTemplate!: HTMLDivElement;
    prevCursor!: string;
    position = { x: 0, y: 0 };
    onMouseMoveThrottled!: (e: any) => void;
    debounceUpdate!: (position: BackgroundPosition) => void;

    created() {
        this.debounceUpdate = debounce(this.updateTemplateSchema, 50);
    }

    get bannerElement() {
        return this.boxTemplate ? (this.boxTemplate.firstElementChild as HTMLDivElement) : null;
    }

    get getSaleValue() {
        return `scale(${this.scaleValue})`;
    }

    onMouseDown() {
        if (this.templateSchema?.backgroundPosition?.includes('px')) this.initPosition();
        if (this.boxTemplate) {
            this.prevCursor = document.body.style.cursor;
            document.body.style.cursor = 'move';
        }
        document.addEventListener('mousemove', this.onMouseMove);
        document.addEventListener('mouseup', this.onMouseUp);
    }

    initPosition() {
        const positionList = this.templateSchema?.backgroundPosition?.match(/(\d+)|(-\d+)/gm) || [0, 0];
        this.position = { x: Number(positionList[0]), y: Number(positionList[1]) };
    }

    onMouseMove(e: any) {
        e.preventDefault();
        this.position = {
            x: this.position.x + e.movementX,
            y: this.position.y + e.movementY,
        };
        const updatePosition = `${this.position.x + e.movementX}px ${this.position.y + e.movementY}px`;
        this.boxTemplate.style.backgroundPosition = updatePosition;
        this.debounceUpdate({ backgroundPosition: updatePosition });
    }

    onMouseUp() {
        document.body.style.cursor = this.prevCursor;
        this.removeListeners();
    }

    removeListeners() {
        document.removeEventListener('mousemove', this.onMouseMove);
        document.removeEventListener('mouseup', this.onMouseUp);
    }
}
