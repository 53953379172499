















import { Component, Vue } from 'vue-property-decorator';
import { EDITOR_MUTATIONS, UpdateTemplateSchema, UpdateWidget } from '@modules/admin/Editor/store';
import { namespace } from 'vuex-class';
import { TemplateWidgetsModels } from '@/packages/template-renderer/types/Widget';
import { BaseWidgetForm } from '../commonForms';
import ImageUploader from '../ImageUploader.vue';

const ns = namespace('admin.editor');

@Component({
    components: {
        BaseWidgetForm,
        ImageUploader,
    },
})
export default class PropertiesListLayer extends Vue {
    @ns.Getter activeWidget!: TemplateWidgetsModels;
    @ns.Mutation(EDITOR_MUTATIONS.updateWidgetProperties)
    updateWidgetProperties!: ({ key: value }: UpdateWidget) => void;
    @ns.Mutation(EDITOR_MUTATIONS.updateTemplateSchema)
    updateTemplateSchema!: ({ propertyName, propertyValue }: UpdateTemplateSchema) => void;

    async handleUploadLayer(value: string) {
        this.updateWidgetProperties({ ['defaultValue']: value });
    }

    async handleDeleteLayer() {
        this.updateWidgetProperties({ ['defaultValue']: '' });
    }
}
