





























import { Component, Vue } from 'vue-property-decorator';
import { FamilyType } from '@models';
@Component
export default class Login extends Vue {
    private rootPath = '/admin';
    private familiesManagementPath = 'families-management';
    private Families = FamilyType;

    get adminRoutes() {
        return this.$router.options.routes
            ?.find(route => route.path === this.rootPath)
            ?.children?.map(({ path, meta, name }) => ({ path, name, meta: meta || {} }));
    }
    get routesForMenu() {
        return this.adminRoutes?.filter(route => !['*', this.familiesManagementPath].includes(route.path));
    }
    get familiesManagementRoute() {
        return this.adminRoutes?.find(route => route.path === this.familiesManagementPath);
    }
    get active() {
        return [this.$route.name];
    }
    navigate(item: any) {
        const route = this.routesForMenu?.find(route => route.name === item.key);
        if (route) this.$router.push({ name: route?.name, params: route?.meta?.defaultParams });
    }
}
