




import { WidgetIconModel } from '@/packages/template-renderer/types/Widget';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { modelToStyles } from '@modules/admin/Editor/utils';
import { IconTemplate } from '@/packages/template-renderer/components';

@Component({ components: { IconTemplate } })
export default class IconPreview extends Vue {
    @Prop() properties!: WidgetIconModel;

    get styles() {
        const { top, left, bottom, right, width, height, zIndex, ...img } = modelToStyles(this.properties);
        return {
            top,
            left,
            bottom,
            right,
            width,
            height,
            zIndex,
        };
    }
}
