import { TemplateListFilters, TemplatePreview } from '@/modules/templates/models';
import { ListFilterParams, ListPageState } from '@/shared/models';
import createPaginationModule from '@/store/modules/paginator';
import templateAdapter from '@adminServices/TemplateAdminApiAdapter';
import { Module } from 'vuex';
import { templatesAdminAdapter } from '@api';
import { FamiliesType } from '@shared/models/FamiliesType';

export interface TemplateState {
    pg?: ListPageState<ListFilterParams, TemplatePreview>;
    filterParams: TemplateListFilters;
    families: FamiliesType[];
}

const module: Module<TemplateState, RootState> = {
    namespaced: true,
    state: {
        filterParams: {
            dimensions: [],
            sort: {},
            types: [],
        },
        families: [],
    },
    actions: {
        async delete(_, ids: number[]) {
            await templateAdapter.deleteTemplate(ids);
        },
        async clone(_, id: number) {
            await templateAdapter.cloneTemplate(id);
        },
        async getFilterParams({ commit }) {
            commit('setFilterParams', await templateAdapter.getFilters());
        },
        async getFamilies({ commit }, params: Partial<ListFilterParams>) {
            commit('setFamilies', await templatesAdminAdapter.getFamilies(params));
        },
    },
    mutations: {
        setFilterParams(state, payload: TemplateListFilters) {
            state.filterParams = payload;
        },
        setFamilies(state, payload: FamiliesType[]) {
            state.families = payload;
        },
    },
    modules: {
        pg: createPaginationModule<ListFilterParams, TemplatePreview>({
            api: templateAdapter,
            initialState: () => ({
                page: {
                    size: 10,
                    index: 0,
                    rows: [],
                    totalItems: 0,
                },
                params: {
                    query: '',
                    sort: 0,
                    sortAsc: true,
                    dimensions: [],
                    tags: [],
                    types: [2],
                    ids: [],
                    familyIds: [],
                },
                dataId: 1,
            }),
            infinite: false,
        }),
    },
};
export default module;
