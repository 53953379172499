







import { Component, Vue } from 'vue-property-decorator';
import { EDITOR_MUTATIONS, UpdateWidget, UpdateTemplateSchema } from '@modules/admin/Editor/store';
import { namespace } from 'vuex-class';
import { TemplateWidgetsModels } from '@/packages/template-renderer/types/Widget';
import { BaseWidgetForm, TypographyWidgetForm } from '../commonForms';
import { TemplateSchema } from "@/packages/template-renderer/types/Template";

const ns = namespace('admin.editor');

@Component({
    components: {
        BaseWidgetForm,
        TypographyWidgetForm,
    },
})
export default class PropertiesListText extends Vue {
    @ns.Getter activeWidget!: TemplateWidgetsModels;
    @ns.Mutation(EDITOR_MUTATIONS.updateWidgetProperties)
    updateWidgetProperties!: ({ key: value }: UpdateWidget) => void;
    @ns.Mutation(EDITOR_MUTATIONS.updateTemplateSchema)
    updateTemplateSchema!: ({ propertyName, propertyValue }: UpdateTemplateSchema) => void;
    @ns.State templateSchema!: TemplateSchema;
}
