































































































import module from './store';
import { PadinationLoadOptions, PAGINATION_ACTIONS, PAGINATION_MUTATIONS } from '@/store/modules/paginator';
import { Column } from 'ant-design-vue/types/table/column';
import {Component, Prop, Ref, Vue} from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { FamilyType, ListFilterParams, RequestForUpdateFamilyType } from '@/shared/models';
import { BackgroundPreview } from '@modules/media/models';
import {FormModel} from "ant-design-vue";
import {SnotifyPosition} from "vue-snotify";

const ns = namespace('admin.manageFamilies');
const pg = namespace('admin.manageFamilies/pg');

@Component<FamiliesManagement>({
    created() {
        this.$registerVuexModule('admin.manageFamilies', module);
    },
})
export default class FamiliesManagement extends Vue {
    @pg.State
    public params!: ListFilterParams;
    @pg.State
    public loading!: boolean;
    @pg.Getter
    public pageSize!: number;
    @pg.Getter
    public total!: number;
    @pg.Getter
    public pageIndex!: number;
    @pg.Getter
    public pageItems!: BackgroundPreview[];
    @pg.Action(PAGINATION_ACTIONS.load)
    public loadPage!: (options?: PadinationLoadOptions) => Promise<boolean>;
    @pg.Mutation(PAGINATION_MUTATIONS.setParams)
    public setParams!: (params: Partial<ListFilterParams>) => void;
    @ns.Action('delete') delete!: (payload: number) => Promise<void>;
    @ns.Action('add') add!: (payload: RequestForUpdateFamilyType) => Promise<void>;
    @ns.Action('getFilterParams') getFilterParams!: () => Promise<void>;
    @pg.Mutation(PAGINATION_MUTATIONS.resetData) resetData!: () => void;
    @Prop() familiesType!: FamilyType;
    @Ref('form') form!: FormModel;
    idEditable = 0;
    listIdDisable = [] as number[];
    editableInputValue = '';

    model = {
        familyInput: undefined as string | undefined,
    };

    rules = {
        familyInput: { required: true, message: 'Field must not be empty' },
    };

    mounted() {
        this.loadPage();
    }

    destroyed() {
        this.resetData();
    }

    onChangeQueryParams(e: any) {
        this.setParams({ query: e.target.value });
        this.loadPage({ page: this.pageIndex });
    }

    onTableParamsChanged(paging: { current: number }) {
        this.loadPage({ page: paging.current - 1 });
    }

    async onDelete(id: number) {
        if (!this.listIdDisable.includes(id)) {
            this.listIdDisable.push(id);
            await this.delete(id);
            const _pageIndex = (this.pageItems.length === 1 && this.pageIndex !== 1) ? this.pageIndex - 1 : this.pageIndex;
            await this.loadPage({ page: _pageIndex });
            this.listIdDisable.filter(_id => _id !== id);
        }
    }

    async onAdd() {
        this.form.validate(async valid => {
            if (valid && this.model.familyInput?.trim()) {
                const requestData = {
                    family: {
                        id: null,
                        familyType: this.familiesType,
                        name: this.model.familyInput,
                    },
                };
                try {
                    await this.add(requestData);
                    this.$snotify.success('Family added', { position: SnotifyPosition.rightTop });
                    this.model.familyInput = '';
                    await this.loadPage({ page: this.pageIndex });
                } catch (error) {
                    throw error;
                }
            }
        });
    }

    onChangeInput(e: any) {
        this.model.familyInput = e.target.value;
    }

    onChangeEditableInput(e: any) {
        this.editableInputValue = e.target.value;
    }

    onEdit(id: number, value: string) {
        this.idEditable = id;
        this.editableInputValue = value;
    }

    async onSave(id: number) {
        if (this.editableInputValue.trim()) {
            const requestData = {
                family: {
                    id: id,
                    familyType: this.familiesType,
                    name: this.editableInputValue,
                },
            };
            try {
                await this.add(requestData);
                await this.loadPage({ page: this.pageIndex });
                this.editableInputValue = '';
                this.idEditable = 0;
            } catch (error) {
                throw error;
            }
        }
    }

    onCancel() {
        this.idEditable = 0;
        this.editableInputValue = '';
    }

    columns: Partial<Column>[] = [
        { title: 'Family name', dataIndex: 'name', scopedSlots: { customRender: 'name' } },
        { title: 'Action', dataIndex: 'id', width: '250px', scopedSlots: { customRender: 'action' } },
    ];
}
