import { Module } from 'vuex';
import getters from './getters';
import mutations from './mutations';
import actions from './actions';

export interface UploadCropState {
    stages: { id: number; active: boolean; valid: boolean; text: string }[];
    file: File | null;
    cropped: Blob | null;
}

export default function createUploaderModule(activeStep = 1): Module<UploadCropState, RootState> {
    return {
        namespaced: true,
        state: {
            stages: [
                {
                    id: 1,
                    text: 'Upload',
                    active: activeStep === 1,
                    valid: false,
                },
                {
                    id: 2,
                    text: 'Crop',
                    active: activeStep === 2,
                    valid: true,
                },
            ],
            file: null,
            cropped: null,
        },
        getters,
        actions,
        mutations,
    };
}
