import {
    iconsAdapter,
    templatesAdapter,
    backgroundsAdapter,
    editorAdapter,
    templatesAdminAdapter,
    manageFamiliesAdapter
} from '@/services/api/adapters';
import { Module } from 'vuex';
import createUploaderModule, { UploadCropState } from '@/store/modules/uploadCrop';
import { TemplateWidgetsModels, TemplateWidgetTypes } from '@/packages/template-renderer/types/Widget';
import { Template as TemplateForSave, TemplateSchema, TemplateSchemaModel } from '@/packages/template-renderer/types/Template';
import { BackgroundPreview, MediaListFilters } from '@/modules/media/models';
import {FamilyType, Icon, LayerImage, ListFilterParams, PageResult, RequestForUpdateFamilyType} from '@models';
import { Template, TemplateModelField } from '@/shared/models/Template';
import { FamiliesType } from '@shared/models/FamiliesType';
import familyAdapter from "@services/api/adapters/FamilyAdapter";

export const EDITOR_MUTATIONS = {
    addWidget: 'addWidget',
    deleteWidget: 'deleteWidget',
    updateWidgetProperties: 'updateWidgetProperties',
    updateTemplateSchema: 'updateTemplateSchema',
    setActiveWidget: 'setActiveWidget',
    setTemplateName: 'setTemplateName',
    setTemplateTag: 'setTemplateTag',
    setTags: 'setTags',
    setIcons: 'setIcons',
    setBackgrounds: 'setBackgrounds',
    addLayer: 'setLayer',
    deleteLayer: 'deleteLayer',
    setEditType: 'setEditType',
    setTemplateId: 'setTemplateId',
    setBackgroundId: 'setBackgroundId',
    setFamilyId: 'setFamilyId',
    setErrors: 'setErrors',
    setBackgroundsFilters: 'setBackgroundsFilters',
    appendToCropperHistory: 'appendToCropperHistory',
    updateCropperHistory: 'updateCropperHistory',
    updateError: 'updateError',
    useSchema: 'useSchema',
    reset: 'reset',
    updateScaleValue: 'updateScaleValue',
    setBgFamilies: 'setBgFamilies',
    setTemplateFamilies: 'setTemplateFamilies',
};

export const EDITOR_ACTIONS = {
    getTags: 'getTags',
    getIcons: 'getIcons',
    uploadLayer: 'uploadLayer',
    getBackgrounds: 'getBackgrounds',
    getBackgroundsFilters: 'getBackgroundsFilters',
    addFamily: 'addFamily',
    getTemplateFamilies: 'getTemplateFamilies',
};

export interface UpdateWidget {
    [x: string]: string | number[] | number | Partial<TemplateWidgetsModels>;
}

export interface UpdateTemplateSchema {
    propertyName: string;
    propertyValue: string;
}

export interface Errors {
    isErrorEmptyBackgroundImage: boolean;
}

export interface UpdateError {
    errorProperty: string;
    errorValue: boolean;
}

export interface ListWidgetTypes {
    name: TemplateWidgetTypes;
}

export enum IconType {
    logo,
    button,
}
export interface Icons {
    [IconType: number]: Icon[];
}

interface SetIcons {
    icons: Icons;
    type: IconType;
}

export interface AdminEditorState {
    listWidgetTypes: ListWidgetTypes[];
    listCreatedWidgets: TemplateWidgetsModels[];
    activeWidgetId: string | number;
    templateSchema: Omit<TemplateSchemaModel, 'widgets'>;
    templateName: string;
    templateTag: string;
    editType: string;
    templateId: number;
    backgroundId: number;
    familyId: number;
    bgFamilies: FamiliesType[];
    templateFamilies: FamiliesType[];
    errors: Errors;
    icons: Icons;
    listBackgrounds: BackgroundPreview[];
    layers: LayerImage[];
    cropperHistory: BackgroundPreview[];
    scaleValue: number;
    uploader?: UploadCropState;
    backgroundsFilters?: MediaListFilters;
}

const getDefaultState = () =>
    ({
        listWidgetTypes: [{ name: 'button' }, { name: 'icon' }, { name: 'text' }, { name: 'layer' }],
        activeWidgetId: '',
        templateSchema: {
            width: 300,
            height: 480,
            backgroundSize: 'cover',
            backgroundPosition: '',
            backgroundImage: '',
            colorPalette: [],
            fontSizes: [],
            backgroundColor: '',
            suffix: '',
        },
        listCreatedWidgets: [],
        templateName: '',
        templateTag: '',
        editType: 'new',
        templateId: 0,
        backgroundId: 0,
        familyId: 0,
        bgFamilies: [],
        templateFamilies: [],
        errors: {
            isErrorEmptyBackgroundImage: false,
        },
        icons: { button: [], logo: [] },
        listBackgrounds: [],
        layers: [],
        cropperHistory: [],
        scaleValue: 1,
        backgroundsFilters: {
            dimensions: [],
            sort: {},
            tags: [],
        },
    } as AdminEditorState);

const module: Module<AdminEditorState, RootState> = {
    namespaced: true,
    state: getDefaultState(),
    actions: {
        async uploadBackground({ state, commit, getters }, bgFamilyId) {
            const file: Blob = getters['uploader/stageFile'];
            if (file) {
                const { backgrounds } = await backgroundsAdapter.upload({ file, name: state.uploader?.file?.name, isTemp: true, familyId: bgFamilyId });
                const bg = backgrounds[0];
                commit(EDITOR_MUTATIONS.updateTemplateSchema, { backgroundImage: bg.defaultUrl });
                commit(EDITOR_MUTATIONS.setBackgroundId, bg.id);
                commit(EDITOR_MUTATIONS.appendToCropperHistory, bg);
            }
        },
        async getIcons({ commit }, type: IconType) {
            const data = {
                index: 0,
                params: {
                    query: '',
                    sort: 0,
                    sortAsc: true,
                    options: type,
                    familyIds: [],
                },
                size: 40,
            };
            const response = await iconsAdapter.getPage(data);
            commit(EDITOR_MUTATIONS.setIcons, { icons: response.rows, type: IconType[type] });
        },
        async getBackgrounds({ commit }, params?: Partial<MediaListFilters>) {
            const defaultParams = {
                query: '',
                sort: 0,
                sortAsc: true,
                dimensions: [],
                tags: [],
                familyIds: [],
            };
            const response = await backgroundsAdapter.getPage({
                index: 0,
                params: params ? Object.assign({}, defaultParams, params) : defaultParams,
                size: 40,
            });
            commit(EDITOR_MUTATIONS.setBackgrounds, response.rows);
        },
        async uploadLayer({ commit }, imageLayer) {
            const response = await editorAdapter.uploadLayer(imageLayer);
            commit(EDITOR_MUTATIONS.addLayer, response.data.images[0]);
            return response.data.images;
        },
        async deleteLayer({ commit, state }, layerUrl) {
            const layer = state.layers.find(l => l.defaultUrl === layerUrl);
            if (layer) {
                commit(EDITOR_MUTATIONS.deleteLayer, layer.imageId);
                await editorAdapter.deleteLayer(layer.imageId);
            }
        },
        async saveTemplate({ state }, templateSchema: TemplateSchema) {
            const modelJson = {
                backgroundPosition: templateSchema.backgroundPosition,
                backgroundSize: templateSchema.backgroundSize,
                backgroundImage: templateSchema.backgroundImage,
                width: templateSchema.width,
                height: templateSchema.height,
                fields: state.listCreatedWidgets.map(widget => ({
                    value: widget.defaultValue,
                    hidden: false,
                    label: widget.label,
                })) as TemplateModelField[],
            };
            const data = {
                Template: {
                    id: state.templateId,
                    isMobile: false,
                    name: state.templateName,
                    content: JSON.stringify(templateSchema),
                    collectionName: state.templateTag,
                    fieldsJson: '{}',
                    modelJson: JSON.stringify(modelJson),
                    BackgroundId: state.backgroundId,
                    width: templateSchema.width,
                    height: templateSchema.height,
                    familyId: state.familyId,
                    layerImageIds: state.layers.map(l => l.imageId),
                    fontIds: [],
                },
            } as TemplateForSave;
            return await editorAdapter.saveTemplate(data);
        },
        async [EDITOR_ACTIONS.getBackgroundsFilters]({ commit }) {
            commit(
                EDITOR_MUTATIONS.setBackgroundsFilters,
                await templatesAdminAdapter.getFamilies({ options: FamilyType.BackgroundFamily })
            );
        },
        async getBackgroundFamilies({ state, commit }) {
            commit('setBgFamilies', await familyAdapter.getPage({ size: 100, params: { query: '', options: FamilyType.BackgroundFamily } }));
        },
        async [EDITOR_ACTIONS.getTemplateFamilies]({ state, commit }, params: Partial<ListFilterParams>) {
            commit('setTemplateFamilies', await templatesAdminAdapter.getFamilies(params));
        },
        async [EDITOR_ACTIONS.addFamily]({dispatch}, data: RequestForUpdateFamilyType) {
            await manageFamiliesAdapter.updateFamily(data);
            await dispatch('getTemplateFamilies', { options: FamilyType.TemplateCollection });
        },
    },
    mutations: {
        [EDITOR_MUTATIONS.useSchema](state, payload: { model: TemplateSchemaModel; template: Template }) {
            const { widgets, ...mainSchema } = payload.model;
            state.templateSchema = {
                ...state.templateSchema,
                ...mainSchema,
                fontSizes: Array.isArray(mainSchema.fontSizes) ? mainSchema.fontSizes : [],
                colorPalette: Array.isArray(mainSchema.colorPalette) ? mainSchema.colorPalette : [],
            };
            state.listCreatedWidgets = widgets;
            state.templateName = payload.template.name;
            state.familyId = payload.template.family.id;
            state.templateTag = payload.template.family.name;
            state.layers = payload.template.layerImages;
            state.backgroundId = payload.template.background.id;
        },
        [EDITOR_MUTATIONS.addWidget](state: AdminEditorState, widget: TemplateWidgetsModels) {
            const updatedWidget = Object.assign(widget, { zIndex: state.listCreatedWidgets.length });
            state.listCreatedWidgets = [updatedWidget].concat(state.listCreatedWidgets);
            state.activeWidgetId = widget.id;
        },
        [EDITOR_MUTATIONS.deleteWidget](state: AdminEditorState, idForDelete: number) {
            state.listCreatedWidgets = state.listCreatedWidgets.filter(item => item.id !== idForDelete);
            if (state.activeWidgetId === idForDelete) state.activeWidgetId = '';
        },
        [EDITOR_MUTATIONS.setActiveWidget](state: AdminEditorState, activeWidgetId: number) {
            state.activeWidgetId = activeWidgetId;
        },
        [EDITOR_MUTATIONS.updateWidgetProperties](state: AdminEditorState, payload: UpdateWidget) {
            state.listCreatedWidgets = state.listCreatedWidgets.map(widget => {
                if (widget.id === state.activeWidgetId) return Object.assign(widget, payload);
                return widget;
            });

            if (payload.hasOwnProperty('zIndex')) {
                state.listCreatedWidgets = state.listCreatedWidgets.sort((a, b) => (b.zIndex > a.zIndex ? 1 : -1));
            }
        },
        [EDITOR_MUTATIONS.updateTemplateSchema](state: AdminEditorState, payload: Partial<TemplateSchemaModel>) {
            state.templateSchema = { ...state.templateSchema, ...payload };
        },
        [EDITOR_MUTATIONS.setTemplateName](state: AdminEditorState, templateName: string) {
            state.templateName = templateName;
        },
        [EDITOR_MUTATIONS.setIcons](state: AdminEditorState, { icons, type }: SetIcons) {
            state.icons = Object.assign(state.icons, { [type]: icons });
        },
        [EDITOR_MUTATIONS.setBackgrounds](state: AdminEditorState, listBackgrounds: BackgroundPreview[]) {
            state.listBackgrounds = listBackgrounds;
        },
        [EDITOR_MUTATIONS.addLayer](state: AdminEditorState, layer: LayerImage) {
            state.layers = state.layers.concat(layer);
        },
        [EDITOR_MUTATIONS.deleteLayer](state, layerId) {
            state.layers = state.layers.filter(l => l.imageId !== layerId);
        },
        [EDITOR_MUTATIONS.setEditType](state: AdminEditorState, editType: string) {
            state.editType = editType;
        },
        [EDITOR_MUTATIONS.setTemplateId](state: AdminEditorState, templateId: number) {
            state.templateId = templateId;
        },
        [EDITOR_MUTATIONS.setBackgroundId](state: AdminEditorState, backgroundId: number) {
            state.backgroundId = backgroundId;
        },
        [EDITOR_MUTATIONS.setFamilyId](state: AdminEditorState, familyId: number) {
            state.familyId = familyId;
        },
        [EDITOR_MUTATIONS.setErrors](state: AdminEditorState, errors: Errors) {
            state.errors = errors;
        },
        [EDITOR_MUTATIONS.appendToCropperHistory](state: AdminEditorState, background: BackgroundPreview) {
            state.cropperHistory = [...state.cropperHistory, background];
        },
        [EDITOR_MUTATIONS.updateCropperHistory](state: AdminEditorState, backgrounds: BackgroundPreview[]) {
            state.cropperHistory = backgrounds;
        },
        [EDITOR_MUTATIONS.updateError](state: AdminEditorState, { errorProperty, errorValue }: UpdateError) {
            Object.assign(state.errors, { [errorProperty]: errorValue });
        },
        [EDITOR_MUTATIONS.reset](state) {
            Object.assign(state, getDefaultState());
        },
        [EDITOR_MUTATIONS.setTemplateTag](state, templateTag: string) {
            state.templateTag = templateTag;
        },
        [EDITOR_MUTATIONS.setBackgroundsFilters](state, payload: MediaListFilters) {
            state.backgroundsFilters = payload;
        },
        [EDITOR_MUTATIONS.updateScaleValue](state, scaleValue) {
            state.scaleValue = scaleValue;
        },
        [EDITOR_MUTATIONS.setBgFamilies](state, families: PageResult<FamiliesType>) {
            state.bgFamilies = families.rows;
        },
        [EDITOR_MUTATIONS.setTemplateFamilies](state, families: FamiliesType[]) {
            state.templateFamilies = families;
        },
    },
    getters: {
        activeWidget: state => {
            return state.listCreatedWidgets.find(widget => state.activeWidgetId === widget.id) || {};
        },
    },
    modules: {
        uploader: createUploaderModule(2),
    },
};

export default module;
