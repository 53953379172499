

















































import module from './store';
import { PadinationLoadOptions, PAGINATION_ACTIONS, PAGINATION_MUTATIONS } from '@/store/modules/paginator';
import { Column } from 'ant-design-vue/types/table/column';
import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { UserActionPreview } from './models';
import { debounce } from '@/shared/utils';
import { ListFilterParams } from '@/shared/models';
import { Modal } from 'ant-design-vue';

const pg = namespace('admin.logs/pg');

@Component({
    created() {
        this.$registerVuexModule('admin.logs', module);
    },
})
export default class Logs extends Vue {
    @pg.State
    public loading!: boolean;
    @pg.State
    public params!: ListFilterParams;
    @pg.Getter
    public pageSize!: number;
    @pg.Getter
    public total!: number;
    @pg.Getter
    public pageIndex!: number;
    @pg.Getter
    public pageItems!: UserActionPreview[];

    @pg.Action(PAGINATION_ACTIONS.load)
    public loadPage!: (options?: PadinationLoadOptions) => Promise<boolean>;

    @pg.Mutation(PAGINATION_MUTATIONS.setParams)
    public setParams!: (params: Partial<ListFilterParams>) => void;

    @pg.Mutation(PAGINATION_MUTATIONS.resetData) resetData!: () => void;

    mounted() {
        this.loadPage();
    }

    destroyed() {
        this.resetData();
    }

    onTableParamsChanged(paging: { current: number }) {
        this.loadPage({ page: paging.current - 1 });
    }

    onSearch(value: Event | string) {
        this.setParams({ query: typeof value === 'string' ? value : (value.target as HTMLInputElement).value });
        this.debouncedLoad();
    }

    debouncedLoad = debounce(() => {
        this.loadPage({ page: 0 });
    }, 500);

    viewDetails(details: string) {
        const prettyJson = this.pretifyJson(details);
        const h = this.$createElement;
        Modal.info({
            content: h('pre', prettyJson),
            width: 650,
        });
    }

    pretifyJson(json: string) {
        try {
            return JSON.stringify(JSON.parse(json), null, 2);
        } catch (e) {
            return json;
        }
    }

    truncate(data: string) {
        const maxLength = 80;
        return data.length > maxLength ? data.substring(0, maxLength) + '...' : data;
    }

    columns: Partial<Column>[] = [
        { title: 'Date', dataIndex: 'date', scopedSlots: { customRender: 'date' } },
        { title: 'User', dataIndex: 'userName', scopedSlots: { customRender: 'user' } },
        { title: 'Area', dataIndex: 'area', scopedSlots: { customRender: 'area' } },
        { title: 'Action', dataIndex: 'action', scopedSlots: { customRender: 'action' } },
        { title: 'Details', dataIndex: 'data', scopedSlots: { customRender: 'details' } },
        { title: '', key: 'userId', dataIndex: 'data', scopedSlots: { customRender: 'view' } },
    ];
}
