


























import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

@Component({
    components: {
        VNodes: Vue.extend<{ vnodes: any }>({
            functional: true,
            render: (c, ctx) => {
                return ctx.props.vnodes;
            },
        }),
    },
})
export default class CollectionName extends Vue {
    @Prop() options!: string[];
    @Prop({ default: undefined }) value!: string;
    newOptions: string[] = [];
    searchValue = '';

    @Watch('options')
    optionsChanged() {
        this.newOptions = [];
        this.$emit('select', undefined);
    }

    get localOptions() {
        return this.options.concat(this.newOptions);
    }

    get allowCreate() {
        return this.searchValue && !this.localOptions.some((option) => option.toLowerCase() === this.searchValue.toLowerCase().trim());
    }

    handleChange(value: string) {
        this.$emit('select', value);
    }

    onInputChanged(value: string) {
        this.searchValue = value;
    }

    filterOption(value: string, option: any) {
        return option.componentOptions.children[0].text.toLowerCase().indexOf(value.toLowerCase()) >= 0;
    }

    onAdd() {
        this.$emit('add', this.searchValue);
    }
}
