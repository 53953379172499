





































import { FormModel } from 'ant-design-vue';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Ref, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { IconType } from '../Editor/store';
import CollectionName from '../components/CollectionName.vue';
import { IconUploadData } from './store';
import { IconsFilterParams } from './types';
import {FamilyType, ListFilterParams, RequestForUpdateFamilyType} from '@models';
import { FamiliesType } from '@shared/models/FamiliesType';

const ns = namespace('admin.icons');

@Component({
    model: {
        prop: 'visible',
        event: 'change',
    },
    components: {
        CollectionName: CollectionName,
    },
})
export default class extends Vue {
    @Prop({ default: false }) visible!: boolean;
    @Prop() type!: number;
    @ns.State
    public filterParams!: IconsFilterParams;
    @ns.State
    public families!: FamiliesType[];
    @ns.Action('getFamilies')
    public getFamilies!: (payload: Partial<ListFilterParams>) => Promise<void>;
    @ns.Action('addFamily') addFamily!: (params: RequestForUpdateFamilyType) => Promise<void>;
    @ns.Action('upload')
    public upload!: (data: IconUploadData) => Promise<void>;

    FamilyType = FamilyType;
    uploading = false;
    uploadingNewFamily = false;
    IconType = IconType;
    model = {
        familyId: undefined as number | undefined,
        familyName: undefined as string | undefined,
        selectedType: FamilyType.IconFamily,
        fileList: [] as File[],
    };

    rules = {
        familyId: { required: true, message: 'Family is required' },
        selectedType: { required: true, message: 'Icon type is required' },
        fileList: { type: 'array', required: true, message: 'Select file to upload' },
    };

    @Watch('model.selectedType')
    updateSelectFamily() {
        this.getFamilies({ options: this.model.selectedType });
    }

    get iconFamilies() {
        return typeof this.model.selectedType === 'number' ? this.families?.map(f => f.name) || [] : [];
    }

    @Ref('form') form!: FormModel;

    @Watch('type', { immediate: true })
    onTypeChange(type: number) {
        if (type === IconType.logo) this.model.selectedType = FamilyType.IconFamily;
        if (type === IconType.button) this.model.selectedType = FamilyType.ButtonIconFamily;
    }

    fileList: Blob[] = [];

    get activeFamily() {
        return this.families.find(f => f.id === this.model.familyId)?.name || '';
    }
    beforeUpload(file: File) {
        this.model.fileList = [file];
        return false;
    }
    handleRemove() {
        this.model.fileList = [];
    }
    select(familyName: string) {
        const familyId = this.families?.find(f => f.name === familyName)?.id || 0;
        this.model.familyId = familyId;
        // this.form.validate();  why it doesnt happend automatically?
    }
    async onAdd(familyName: string) {
        const requestData = {
            family: {
                id: null,
                familyType: this.model.selectedType,
                name: familyName,
            },
        };
        try {
            this.uploadingNewFamily = true;
            await this.addFamily(requestData);
            this.model.familyId = this.families.find(f => f.name === familyName)?.id || 0;
        } catch (err) {
            // Todo
        } finally {
            this.uploadingNewFamily = false;
        }
    }
    reset() {
        this.form.resetFields();
        this.model.selectedType = this.type;
    }
    preparedPurpose(type: number) {
        if (type === FamilyType.IconFamily) return IconType.logo;
        if (type === FamilyType.ButtonIconFamily) return IconType.button;
    }
    async uploadIcon() {
        this.form.validate(async valid => {
            if (valid) {
                this.uploading = true;
                try {
                    await this.upload({
                        file: this.model.fileList[0],
                        purpose: this.preparedPurpose(this.model.selectedType),
                        familyId: this.model.familyId || 0,
                    });
                    this.reset();
                    this.$emit('change', false);
                    this.$emit('success');
                } catch (error) {
                    console.error(error);
                }
                this.uploading = false;
            }
        });
    }
}
